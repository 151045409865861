<script>
import RefundGuide from '@/components/journal/parent/concert/RefundGuide.vue'

import api from '@/api'
import { getErrors } from '@/errors'
import Big from 'big.js'

export default {
  name: 'TicketDialog',

  components: {
    RefundGuide
  },

  props: ['concert', 'reservations', 'children'],

  data () {
    return {
      selectedChildID: this.children[0].id,
      isPending: false,
      errors: {}
    }
  },

  computed: {
    reservationIDs () {
      return this.reservations.map((reservation) => reservation.id)
    },
    reservationCount () {
      return this.reservations.length
    },

    reservationTotal () {
      return this.reservations.reduce((acc, reservation) => acc.plus(reservation.price), Big(0))
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    submit () {
      this.isPending = true
      this.errors = {}

      api.post('/tickets/buy/', {
        child: this.selectedChildID,
        reservations: this.reservationIDs
      })
        .then(response => {
          this.$emit('purchase', response.data)
        }, error => {
          this.errors = getErrors(error)
        })
        .then(() => {
          this.isPending = false
        })
    }
  }
}
</script>

<template>
  <div class="dialog-wrapper">
    <div class="dialog-overlay" @click="close"></div>
    <div class="dialog-box">
      <div class="dialog-header">
        <div class="dialog-header-text">Купить билеты</div>
        <BaseCloseButton @click="close" />
      </div>
      <form @submit.prevent="submit">
        <div class="dialog-content">
          <BaseSpinner v-if="isPending" size="50%" :withBackground="true"/>
          <div class="form-field">
            <div class="form-field-label">Концерт</div>
            <div class="form-field-input">{{ concert.name }}</div>
          </div>
          <div class="form-field">
            <div class="form-field-label">Дата</div>
            <div class="form-field-input">{{ $utils.formatDateTime(concert.date, {weekday: 'long'}) }}</div>
          </div>
          <div class="form-field">
            <div class="form-field-label">Мест</div>
            <div class="form-field-input">{{ reservationCount }}</div>
          </div>
          <div class="form-field">
            <div class="form-field-label">Сумма</div>
            <div class="form-field-input">{{ reservationTotal }} ₽</div>
          </div>
          <div class="form-field" v-if="children.length > 1">
            <div class="form-field-label">Счёт</div>
            <div class="form-field-input">
              <div class="child-list">
                <label v-for="child in children" :key="child.id">
                  <input type="radio" :value="child.id" v-model="selectedChildID">
                  {{ child.first_name }}
                </label>
              </div>
            </div>
          </div>
          <BaseAlert v-if="errors.non_field_errors">
            <div v-for="(error, i) in errors.non_field_errors" :key="i">
              {{ error }}
            </div>
          </BaseAlert>
          <BaseAlert v-if="errors.detail">{{ errors.detail }}</BaseAlert>
          <RefundGuide />
        </div>
        <div class="dialog-buttons">
          <button type="submit" class="button no-margin" :disabled="isPending">Купить</button>
          <button type="button" class="button no-background no-margin" @click="close">Отмена</button>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>
.form-field {
  display: flex;
  margin: 0.3em 0;
}

.form-field-label {
  flex: 1;
  margin-right: 1em;
  font-weight: bold;
  text-align: right;
}

.form-field-input {
  width: 60%;
}
</style>
