<script>
import Toggleable from '@/mixins/Toggleable'
import { dateToISO } from '@/utils'

export default {
  name: 'BaseDatepicker',

  mixins: [Toggleable],

  props: ['date'],

  computed: {
    marks () {
      if (!this.date) return {}

      return {
        [dateToISO(this.date)]: true
      }
    }
  },

  methods: {
    onDateSelect (date) {
      this.close()
      this.$emit('update:date', date)
    }
  }
}
</script>

<template>
  <div v-on-clickaway="close" class="dropdown-wrapper">
    <slot :open="open" :isOpen="isOpen"></slot>
    <div v-show="isOpen" class="dropdown-dialog">
      <BaseCalendar
        :showYearSelector="true"
        :isSelectable="true"
        :marks="marks"
        @select="onDateSelect"
      />
    </div>
  </div>
</template>

<style scoped>
.dropdown-wrapper {
  position: relative;
}

.dropdown-dialog {
  position: absolute;
  top: 6em;
  left: 0;

  font-size: 66%;

  color: var(--dark-gray);

  padding: 1em;

  background-color: var(--light-gray);

  z-index: 2;

  border-radius: 1em;
  box-shadow: 0 1px 5px var(--shadow);

  display: flex;
  flex-direction: column;
}
</style>
