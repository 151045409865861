<script>
export default {
  name: 'PriceListDialog',

  inject: ['texts'],

  props: [
    'child'
  ],

  computed: {
    priceList () {
      return this.texts.price_list
    }
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<template>
  <div class="dialog-wrapper">
    <div class="dialog-overlay" @click="close"></div>
    <div class="dialog-box">
      <div class="dialog-header">
        <div class="dialog-header-text">Прейскурант</div>
        <BaseCloseButton @click="close" />
      </div>
      <div class="dialog-content price-list-text" v-html="priceList"></div>
      <div class="dialog-buttons">
        <button class="button green no-margin" @click="close">OK</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.dialog-box {
  width: 60em;
}

.price-list-text {
  line-height: 1.4em;
  font-weight: normal;
}

.price-list-text:deep(ol) {
  padding-left: 1em;
}
</style>
