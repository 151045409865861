export default {
  data () {
    return {
      isOpen: false
    }
  },

  methods: {
    toggle () {
      this.isOpen = !this.isOpen
    },

    open () {
      this.isOpen = true
    },

    close () {
      this.isOpen = false
    }
  }
}
