<template>
  <div class="card">
    <div class="card-content">
      <div class="header" @click.prevent="toggle">
        <font-awesome-icon
          icon="angle-down"
          :class="['arrow', {'rotate-180': isOpen}]"
        />
        <h2 v-if="!isOpen" class="header-text">{{ group.name }}</h2>
        <BaseSpinner
          v-else-if="isPending"
          :withOverlay="false"
          size="30%"
          class="white"
        />
      </div>
      <GroupDetail
        v-if="isOpen && children && teachers"
        :group="group"
        :children="children"
        :teachers="teachers"
        @startGroupMessage="group => $emit('startGroupMessage', group)"
        @startTeacherMessage="teacher => $emit('startTeacherMessage', teacher)"
        @showChildInfo="child => $emit('showChildInfo', child)"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import GroupDetail from '@/components/journal/employee/group/GroupDetail.vue'

import api from '@/api'
import eventBus from '@/eventBus'
import { getErrors } from '@/errors'
import Toggleable from '@/mixins/Toggleable'

export default {
  name: 'GroupCard',

  components: {
    GroupDetail
  },

  mixins: [Toggleable],

  props: [
    'group'
  ],

  data () {
    return {
      children: null,
      teachers: null,
      isPending: false
    }
  },

  mounted () {
    const groupToShow = Number(this.$route.query.group)

    if (this.group.id === groupToShow) {
      this.open()
      this.$el.scrollIntoView()
    }
  },

  created () {
    eventBus.on('updateChild', this.onChildUpdate)
  },

  beforeUnmount () {
    eventBus.off('updateChild', this.onChildUpdate)
  },

  methods: {
    fetchPeople () {
      this.isPending = true

      axios.all([
        api.get('/children/', { params: { group: this.group.id } }),
        api.get('/teachers/', { params: { child_groups: this.group.id } })
      ])
        .then(([children, teachers]) => {
          this.children = children.data
          this.teachers = teachers.data
        }, error => {
          this.$toast.error(getErrors(error).detail)
        })
        .then(() => {
          this.isPending = false
        })
    },

    onChildUpdate (child) {
      if (!this.isOpen) return
      if (child.group.id !== this.group.id) return

      this.fetchPeople()
    }
  },

  watch: {
    isOpen (isOpen) {
      if (isOpen && !this.children && !this.teachers && !this.isPending) {
        this.fetchPeople()
      }
    }
  }
}
</script>

<style scoped>
.card {
  width: 768px;

  margin: 1em 0;
  padding: 1em;

  border-radius: 1em;

  background-color: var(--medium-overlay);
}

.card-content {
  --left-pane-width: 40%;

  border-radius: 0.5em;
  overflow: hidden;
}

.header {
  position: relative;

  height: 3em;

  background-color: var(--green);

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  user-select: none;
}

.header-text {
  margin: 0;

  color: white;

  font-size: 1.5em;
  text-align: center;
  text-shadow: 0px 1px 1px var(--shadow);
}

.arrow {
  color: var(--white-overlay);
  font-size: 2em;

  position: absolute;
  left: 1em;

  transition: transform 0.2s;
}

.rotate-180 {
  transform: rotate(-180deg);
}

@media screen and (max-width: 768px) {
  .card {
    width: 100%;

    padding: 0;
    margin: 0;

    border-radius: 0;
  }

  .card-content {
    border-radius: 0;
  }
}
</style>
