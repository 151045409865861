<template>
  <div class="navigation">
    <router-link class="logo" to="/">
      <img src="../../static/images/logo.png" alt="ЛаЛаЛэнд"/>
    </router-link>
    <div class="menus">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 5em;
  padding: 0.5em 2em;
}

.logo {
  height: 100%;
}

.logo img {
  max-width: 100%;
  max-height: 100%;
}

.menus {
  display: flex;
  align-items: center;
}
</style>
