export function getErrors (error) {
  if (error.response) {
    if (error.response.status === 500) {
      return { detail: 'Внутренняя ошибка сервера.' }
    }
    return error.response.data instanceof Object
      ? error.response.data
      : { detail: error.response.statusText }
  } else if (error.request) {
    return { detail: 'Нет ответа от сервера.' }
  } else {
    return { detail: error.message }
  }
}
