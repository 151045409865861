<template>
  <div class="login-page">
    <div class="dialog-overlay medium" @click="close"></div>
    <div class="form-box">
      <BaseSpinner v-if="isPending"/>
      <BaseCloseButton @click="close" class="button-close-floating" />
      <div class="logo">
        <img src="../static/images/logo.png"/>
      </div>
      <BaseAlert v-if="isPasswordSent" color="success">
        Пароль для входа в личный кабинет был отправлен на указанный номер телефона.
      </BaseAlert>
      <BaseAlert v-if="errors.non_field_errors">
        <div v-for="(error, i) in errors.non_field_errors" :key="i">
          {{ error }}
        </div>
      </BaseAlert>
      <BaseAlert v-if="errors.detail">{{ errors.detail }}</BaseAlert>
      <template v-if="state === State.logIn">
        <form class="form" @submit.prevent="logIn">
          <input
            name="username"
            key="username-login"
            type="tel"
            placeholder="Ваш номер телефона"
            :class="['form-input', {'has-errors': !!errors.username}]"
            v-model="phoneNumber"
            v-focus="!isPasswordSent"
            :disabled="isPending"
            required
          >
          <BaseAlert v-if="errors.username">
            <div v-for="(error, i) in errors.username" :key="i">
              {{ error }}
            </div>
          </BaseAlert>
          <input
            name="password"
            type="password"
            placeholder="Пароль"
            :class="['form-input', {'has-errors': !!errors.password}]"
            v-model="password"
            v-focus="isPasswordSent"
            :disabled="isPending"
            required
          >
          <BaseAlert v-if="errors.password">
            <div v-for="(error, i) in errors.password" :key="i">
              {{ error }}
            </div>
          </BaseAlert>
          <div class="forgot-password-link-wrapper">
            <a class="forgot-password-link" @click="state = State.createPasswordRecoveryRequest">
              Не помню пароль
            </a>
          </div>
          <button :disabled="isPending" class="button">Войти</button>
        </form>
      </template>
      <template v-else-if="state === State.createPasswordRecoveryRequest">
        <form class="form" @submit.prevent="createPasswordRecoveryRequest">
          <input
            name="username"
            key="username-recover"
            type="tel"
            placeholder="Ваш номер телефона"
            :class="['form-input', {'has-errors': !!errors.username}]"
            v-model="phoneNumber"
            v-focus
            :disabled="isPending"
            required
          >
          <BaseAlert v-if="errors.username">
            <div v-for="(error, i) in errors.username" :key="i">
              {{ error }}
            </div>
          </BaseAlert>
          <button :disabled="isPending" class="button">
            Восстановить пароль
          </button>
          <button @click.prevent="state = State.logIn" class="button no-background no-margin">
            Отмена
          </button>
        </form>
      </template>
      <template v-else-if="state === State.completePasswordRecovery">
        <BaseAlert color="warning">
          Для сброса пароля введите код, отправленный на указанный вами номер.
        </BaseAlert>
        <form class="form" @submit.prevent="completePasswordRecovery">
          <input
            name="code"
            key="code"
            placeholder="Код из SMS"
            :class="['form-input', {'has-errors': !!errors.code}]"
            v-model="code"
            v-focus
            :disabled="isPending"
            maxlength="4"
            required
            autocomplete="off"
          >
          <BaseAlert v-if="errors.code">
            <div v-for="(error, i) in errors.code" :key="i">
              {{ error }}
            </div>
          </BaseAlert>
          <button :disabled="isPending" class="button">
            Подтвердить
          </button>
          <button @click.prevent="state = State.logIn" class="button no-background no-margin">
            Отмена
          </button>
        </form>
      </template>
    </div>
  </div>
</template>

<script>
import { getToken, setToken } from '@/authToken'
import api from '@/api'
import { getErrors } from '@/errors'

const State = {
  logIn: 'logIn',
  createPasswordRecoveryRequest: 'createPasswordRecoveryRequest',
  completePasswordRecovery: 'completePasswordRecovery'
}

export default {
  name: 'LoginPage',

  data () {
    return {
      state: State.logIn,

      isPending: false,
      errors: {},

      phoneNumber: '',
      password: '',

      passwordRecoveryRequestID: null,
      code: '',
      isPasswordSent: false
    }
  },

  created () {
    this.State = State
  },

  beforeRouteEnter (to, from, next) {
    if (getToken()) {
      next('/journal')
    } else {
      next()
    }
  },

  methods: {
    logIn () {
      this.isPending = true
      this.errors = {}

      api.post('/obtain-auth-token/', {
        username: this.phoneNumber,
        password: this.password
      })
        .then(response => {
          this.isPending = false
          setToken(response.data.token)
          this.$router.push('/journal')
        }, error => {
          this.isPending = false
          this.errors = getErrors(error)
        })
    },

    createPasswordRecoveryRequest () {
      this.isPending = true
      this.errors = {}

      api.post('/password-recovery-requests/', {
        username: this.phoneNumber
      })
        .then(response => {
          this.isPending = false

          this.passwordRecoveryRequestID = response.data.id

          if (response.data.is_code_required) {
            this.state = State.completePasswordRecovery
          } else {
            this.completePasswordRecovery()
          }
        }, error => {
          this.isPending = false
          this.errors = getErrors(error)
        })
    },

    completePasswordRecovery () {
      this.isPending = true
      this.errors = {}

      api.post(`/password-recovery-requests/${this.passwordRecoveryRequestID}/complete/`, {
        code: this.code
      })
        .then(() => {
          this.isPending = false
          this.state = State.logIn
          this.isPasswordSent = true
        }, error => {
          this.isPending = false
          this.errors = getErrors(error)
        })
    },

    close () {
      this.$router.push('/')
    }
  },

  watch: {
    phoneNumber (newValue) {
      if (newValue.startsWith('8')) {
        this.phoneNumber = `+7${newValue.substring(1)}`
      } else if (newValue.startsWith('9')) {
        this.phoneNumber = `+7${newValue}`
      } else if (newValue.startsWith('7')) {
        this.phoneNumber = `+${newValue}`
      }
    },

    state (state) {
      this.errors = {}

      if (state === State.logIn) {
        this.passwordRecoveryRequestID = null
      } else if (state === State.createPasswordRecoveryRequest) {
        this.code = ''
        this.isPasswordSent = false
      }
    }
  }
}
</script>

<style scoped>
.login-page {
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
}

.button-close-floating {
  position: absolute;

  top: 1.5em;
  right: 1.5em;
}

.forgot-password-link-wrapper {
  text-align: right;
}

.forgot-password-link {
  cursor: pointer;
  font-size: 90%;
  text-decoration: underline;
  color: var(--red-darker);
}
</style>
