<script>
import OrderDialog from '@/components/journal/parent/merch/OrderDialog.vue'

export default {
  name: 'MerchCard',

  components: {
    OrderDialog
  },

  props: ['item', 'children'],

  data () {
    return {
      isOrderDialogVisible: false
    }
  },

  methods: {
    showOrderDialog () {
      this.isOrderDialogVisible = true
    },

    hideOrderDialog () {
      this.isOrderDialogVisible = false
    },

    onPurchase () {
      this.hideOrderDialog()
      this.$toast.success('Успешно приобретено')
      this.$emit('purchase')
    }
  }
}
</script>

<template>
  <div class="card">
    <OrderDialog
      v-if="isOrderDialogVisible"
      @purchase="onPurchase"
      @close="hideOrderDialog"
      :item="item"
      :children="children"
    />
    <div class="card-content">
      <div class="header">
        <h2 class="header-text">
          <div>{{ item.name }}</div>
          <div v-if="item.short_description" class="subheader-text">{{ item.short_description }}</div>
        </h2>
        <div class="order">
          <div v-if="item.order_count" class="order-info">
            Куплено {{ item.order_count }} шт.
          </div>
          <button @click="showOrderDialog" class="button green no-margin">
            {{ item.order_count ? 'Докупить' : 'Купить' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card {
  width: 768px;

  margin: 1em 0;
  padding: 1em;

  border-radius: 1em;

  background-color: var(--medium-overlay);
}

.card-content {
  position: relative;
  z-index: 0;

  border-radius: 0.5em;
  overflow: hidden;
}

.header {
  padding: 2em;

  background-color: var(--red);

  color: white;

  text-shadow: 0px 1px 1px var(--shadow);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-text {
  margin: 0;

  font-size: 2em;

  display: flex;
  flex-direction: column;
}

.subheader-text {
  font-size: 50%;
}

.order {
  text-align: center;
}

.order-info {
  margin-bottom: 0.5em;
}

@media screen and (max-width: 768px) {
  .card {
    width: 100%;

    padding: 0;
    margin: 0;

    border-radius: 0;
  }

  .card-content {
    border-radius: 0;
  }

  .header {
    flex-direction: column;
  }

  .header-text {
    margin-bottom: 0.5em;
    align-items: center;
  }
}
</style>
