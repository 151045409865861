<script setup>
import chroma from 'chroma-js'
import { computed, ref, defineProps, onMounted, watch } from 'vue'

const props = defineProps(['seat', 'isPending'])

const color = computed(() => props.seat.color)
const colorBrighter = computed(() => chroma(color.value).brighten(2.5))
const colorDarker = computed(() => chroma(color.value).darken(2.5))
const style = computed(() => ({
  transform: `translate(-50%, -50%) translate(${props.seat.x}px, ${props.seat.y}px)`
}))

const seatElementRef = ref()

onMounted(() => {
  watch([color, colorBrighter, colorDarker], ([_color, _colorBrighter, _colorDarker]) => {
    seatElementRef.value.style.setProperty('--seat-color', _color)
    seatElementRef.value.style.setProperty('--seat-color-brighter', _colorBrighter)
    seatElementRef.value.style.setProperty('--seat-color-darker', _colorDarker)
  }, { immediate: true })
})
</script>

<template>
  <div
    ref="seatElementRef"
    :style="style"
    :class="[
      'seat',
      {'reserved': !!props.seat.reservation},
      {'unavailable': !props.seat.is_available},
      {'pending': isPending},
    ]"
  >
    <BaseSpinner v-if="isPending" size="8px" :color="colorDarker" />
    <div v-else class="seat-number">{{ props.seat.number }}</div>
  </div>
</template>

<style scoped>
.seat {
  --seat-color: var(--green);
  --seat-color-brighter: var(--green-lightest);
  --seat-color-darker: var(--dark-gray);

  position: absolute;

  color: white;
  background-color: var(--seat-color);

  width: 90px;
  height: 90px;

  border-radius: 9999px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 35px;

  cursor: pointer;
}

.seat.pending {
  border: 0.2em solid var(--seat-color);
  background-color: var(--seat-color-brighter);
}

.seat.unavailable {
  background-color: var(--medium-overlay);
  cursor: not-allowed;
}

.seat.reserved {
  color: var(--seat-color-darker);
  border: 0.2em solid var(--seat-color);
  background-color: var(--seat-color-brighter);
  font-weight: bold;
}

.seat-number {

}
</style>
