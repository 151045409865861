<template>
  <div
    class="teacher-hour-wrapper"
    @click="toggle"
    :title="tooltip"
  >
    <div
      :class="[
        'teacher-hour',
        {'marked': isMarked},
        {'confirmed': isConfirmed},
        {'pending': isPending}
      ]"
    ></div>
  </div>
</template>

<script>
import api from '@/api'
import { getErrors } from '@/errors'

export default {
  name: 'TeacherHourMark',

  props: [
    'teacher',
    'date',
    'group',
    'teacherHour'
  ],

  data () {
    return {
      isPending: false
    }
  },

  computed: {
    isMarked () {
      return !!this.teacherHour
    },

    isConfirmed () {
      return this.teacherHour && this.teacherHour.is_confirmed
    },

    tooltip () {
      return this.teacherHour && `Отметил: ${this.teacherHour.created_by}`
    }
  },

  methods: {
    showErrors (error) {
      const errors = getErrors(error)
      this.$toast.error(
        errors.detail ||
        (errors.non_field_errors && errors.non_field_errors.join(' '))
      )
    },

    toggle () {
      if (this.isPending) {
        return
      }

      this.isPending = true

      let promise

      if (this.teacherHour) {
        promise = api.delete(`/journal/teacher-hours/${this.teacherHour.id}/`)
          .then(() => {
            this.$emit('update:teacherHour', null)
          }, this.showErrors)
      } else {
        promise = api.post('/journal/teacher-hours/', {
          teacher: this.teacher.id,
          group: this.group.id,
          date: this.date
        })
          .then(response => {
            this.$emit('update:teacherHour', response.data)
          }, this.showErrors)
      }

      promise
        .then(() => {
          this.isPending = false
        })
    }
  }
}
</script>

<style scoped>
.teacher-hour-wrapper {
  width: 2em;
  height: 2em;

  padding: 0.4em;

  cursor: pointer;

  position: relative;
}

.teacher-hour {
  width: 100%;
  height: 100%;

  border-radius: 40%;

  background-color: var(--light-overlay);
}

.teacher-hour.pending {
  background-color: var(--light-gray);
  filter: brightness(0.7);
}

.teacher-hour.marked {
  border: 0.2em solid var(--green);
  background-color: var(--green-lightest);
}

.teacher-hour.marked.confirmed {
  background-color: var(--green);
}
</style>
