<template>
  <div :class="['alert', color]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseAlert',

  props: {
    color: {
      type: String,
      default: 'danger'
    }
  }
}
</script>

<style scoped>
.alert {
  padding: 0.8em;
  margin: 0.5em 0;

  border-radius: 0.5em;

  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.alert:first-child {
  margin-top: 0;
}

.alert:last-child {
  margin-bottom: 0;
}

.danger {
  background-color: var(--red-darker);
  color: white;
}

.success {
  background-color: var(--green);
  color: white;
}

.warning {
  background-color: var(--medium-overlay);
  color: var(--dark-gray);
}
</style>
