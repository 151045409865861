import mitt from 'mitt'

export const Event = {
  sendMessage: 'sendMessage',
  receiveMessage: 'receiveMessage',
  confirm: 'confirm'
}

const eventBus = mitt()

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', event => {
    const { type, payload } = event.data

    if (type === 'message') {
      eventBus.emit('receiveMessage', payload)
    }
  })
}

export default eventBus
