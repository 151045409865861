import axios from 'axios'

import { getToken } from '@/authToken'

export const rootAPI = axios.create({
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken'
})

const api = axios.create({
  baseURL: '/api'
})

api.interceptors.request.use(config => {
  const token = getToken()
  if (token) {
    config.headers.Authorization = `Token ${token}`
  }
  return config
})

export default api
