<template>
  <div class="week">
    <div @click="decrementWeek" class="week-button">
      <font-awesome-icon icon="angle-left" />
    </div>
    <div class="days">
      <div
        v-for="day in days"
        :key="day.value"
        class="day"
      >
        <div class="week-day">{{ day.weekDay }}</div>
        <div class="month">{{ day.monthShort }}</div>
        <div class="date">{{ day.date }}</div>
        <div
          v-if="day.isToday"
          class="today"
          :style="{height: contentHeight}"
        ></div>
      </div>
    </div>
    <div @click="incrementWeek" class="week-button">
      <font-awesome-icon icon="angle-right" />
    </div>
  </div>
</template>

<script>
import { addDays, dateToISO } from '@/utils'

const shortMonthNames = [
  'Янв', 'Февр', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг',
  'Сент', 'Окт', 'Нояб', 'Дек'
]

const weekDays = [
  'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'
]

export default {
  name: 'BaseWeek',

  props: [
    'contentHeight'
  ],

  data () {
    const now = new Date()
    const weekDay = now.getDay() || 7
    return {
      today: addDays(now, 0),
      weekStart: addDays(now, 1 - weekDay)
    }
  },

  computed: {
    dates () {
      const dates = []

      for (let i = 0; i < 7; i++) {
        dates.push(addDays(this.weekStart, i))
      }

      return dates
    },

    datesISO () {
      return this.dates.map(dateToISO)
    },

    days () {
      return this.dates.map((date, i) => ({
        weekDay: weekDays[i],
        monthShort: shortMonthNames[date.getMonth()],
        date: date.getDate(),
        isToday: date.valueOf() === this.today.valueOf()
      }))
    }
  },

  methods: {
    decrementWeek () {
      this.weekStart = addDays(this.weekStart, -7)
    },

    incrementWeek () {
      this.weekStart = addDays(this.weekStart, +7)
    }
  },

  watch: {
    datesISO: {
      handler (dates) {
        this.$emit('changeRange', {
          dates,
          rangeFrom: dates[0],
          rangeTo: dates[dates.length - 1]
        })
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.week {
  height: 100%;

  display: flex;
  align-items: center;

  user-select: none;

  font-weight: 300;
}

.week-button {
  font-size: 2em;
  padding: 0.5em;
  width: 1.5em;
  cursor: pointer;

  color: var(--green);
  opacity: 0.4;
}

.days {
  flex: 1;
  align-self: stretch;

  display: flex;
  justify-content: space-around;
}

.day {
  width: 3em;

  padding: 0.3em;

  font-size: 0.8em;

  border-radius: 9999px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
}

.today {
  content: '';

  position: absolute;
  z-index: 0;

  top: 0;
  left: 0;
  right: 0;

  height: 100%;

  background-color: var(--green);
  opacity: 0.1;
}

.month {
  margin: 0.5em 0;
  color: var(--medium-gray);
}

.date {
  font-size: 1.8em;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
