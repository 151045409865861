<script setup>
import { useRoute, useRouter } from 'vue-router'

import { getToken } from '@/authToken'

const route = useRoute()
const router = useRouter()

const isSuccess = !route.query.fail

const close = () => {
  if (getToken()) {
    router.push('/journal')
  } else {
    router.push('/')
  }
}
</script>

<template>
  <div class="content">
    <div class="dialog-wrapper">
      <div class="dialog-overlay medium" @click="close"></div>
      <div class="dialog-box">
        <div class="dialog-header">
          <div class="dialog-header-text">Пополнение баланса</div>
          <BaseCloseButton @click="close" />
        </div>
        <div :class="['dialog-content', {'fail': !isSuccess}]">
          <FontAwesomeIcon
            :icon="isSuccess ? 'check-circle' : 'times-circle'"
            class="fa-4x"
            fixed-width
          />
          <div class="message">
            {{ isSuccess ? 'Платёж принят' : 'Платёж отклонён' }}
          </div>
        </div>
        <div class="dialog-buttons">
          <button
            class="button green no-margin"
            @click="close"
          >
            {{ getToken() ? 'Вернуться в личный кабинет' : 'Вернуться на главную' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  color: var(--green-darker);
}

.dialog-content.fail {
  color: var(--red-darker);
}

.message {
  margin-top: 0.5em;
  font-size: 1.5em;
}
</style>
