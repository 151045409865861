<template>
  <div class="journal-page">
    <PushNotifications :user="user" />
    <div v-if="user.is_teacher" class="tab-links">
      <router-link to="/journal" class="tab-link" exact-active-class="active">
        Журнал посещений
      </router-link>
      <router-link to="/journal/requests" class="tab-link" exact-active-class="active">
        Внесение в базу
      </router-link>
      <router-link to="/journal/corrections" class="tab-link" exact-active-class="active">
        Корректировки
      </router-link>
    </div>
    <router-view :user="user"/>
  </div>
</template>

<script>
import PushNotifications from '@/components/journal/PushNotifications.vue'

export default {
  name: 'EmployeePage',

  components: {
    PushNotifications
  },

  props: [
    'user'
  ]
}
</script>

<style scoped>
.journal-page {
  position: relative;

  flex: 1;

  display: flex;
  flex-direction: column;
}
</style>
