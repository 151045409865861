<script>
export default {
  name: 'MessagesButton',

  props: ['isOpen']
}
</script>

<template>
  <div class="notification-button">
    <div v-if="isOpen" class="pointer"></div>
    <slot></slot>
  </div>
</template>

<style scoped>
.notification-button {
  position: relative;

  margin-right: 1em;
  color: #ddd;

  cursor: pointer;
}

.pointer {
  position: absolute;
  z-index: 3;
  left: calc(50% - 1em);
  bottom: -1.5em;

  border: 1em solid transparent;
  border-bottom-color: var(--light-gray);

  pointer-events: none;
}
</style>
