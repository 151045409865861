<script>
import ConfirmMarksButton from '@/components/journal/employee/ConfirmMarksButton.vue'
import ReportButton from '@/components/journal/employee/ReportButton.vue'
import GroupCard from '@/components/journal/employee/group/GroupCard.vue'
import MessageDialog from '@/components/journal/employee/dialogs/MessageDialog.vue'
import MessageMultipleDialog from '@/components/journal/employee/dialogs/MessageMultipleDialog.vue'
import ChildInfoDialog from '@/components/journal/employee/dialogs/ChildInfoDialog.vue'
import ChildNotesDialog from '@/components/journal/employee/dialogs/ChildNotesDialog.vue'
import ChildArchiveDialog from '@/components/journal/employee/dialogs/ChildArchiveDialog.vue'

import axios from 'axios'
import api from '@/api'
import { getErrors } from '@/errors'

export default {
  name: 'EmployeeJournal',

  components: {
    ConfirmMarksButton,
    ReportButton,
    GroupCard,
    MessageDialog,
    MessageMultipleDialog,
    ChildInfoDialog,
    ChildNotesDialog,
    ChildArchiveDialog
  },

  props: [
    'user'
  ],

  created () {
    this.fetchData()
  },

  data () {
    return {
      isPending: false,
      groups: null,
      teachers: null,

      dialogs: {
        message: null,
        messageMultiple: null,
        childInfo: null,
        childNotes: null,
        childArchive: null
      },

      child: null
    }
  },

  computed: {
    canConfirmMarks () {
      return 'journal.confirm_marks' in this.user.permissions
    }
  },

  methods: {
    fetchData () {
      this.isPending = true

      axios.all([
        api.get('/groups/'),
        api.get('/teachers/')
      ])
        .then(([groups, teachers]) => {
          this.groups = groups.data
          this.teachers = teachers.data
        }, error => {
          this.$toast.error(getErrors(error).detail)
        })
        .then(() => {
          this.isPending = false
        })
    },

    startGroupMessage (group) {
      this.dialogs.message = {
        url: `/groups/${group.id}/message/`,
        recipientsText: group.name,
        placeholder: 'Написать сообщение группе...',
        isGroup: true
      }
    },

    startChildMessage (child) {
      this.dialogs.message = {
        url: `/children/${child.id}/message/`,
        recipientsText: `${child.last_name} ${child.first_name}`,
        placeholder: 'Написать личное сообщение...'
      }
    },

    startTeacherMessage (teacher) {
      const recipients = this.teachers.map(teacher => ({
        id: teacher.id,
        name: `${teacher.last_name} ${teacher.first_name}`
      }))

      this.dialogs.messageMultiple = {
        url: '/teachers/message/',
        placeholders: {
          none: 'Выберите получателей',
          one: 'Написать сообщение преподавателю...',
          multiple: 'Написать сообщение преподавателям...'
        },
        recipients: recipients,
        preselected: [teacher.id]
      }
    },

    cancelMessage () {
      this.dialogs.message = null
      this.dialogs.messageMultiple = null
    },

    showChildInfo (child) {
      this.dialogs.childInfo = { child }
    },

    hideChildInfo () {
      this.dialogs.childInfo = null
    },

    showChildNotes (child) {
      this.dialogs.childNotes = { child }
    },

    hideChildNotes () {
      this.dialogs.childNotes = null
    },

    showChildArchive (child) {
      this.dialogs.childArchive = { child }
    },

    hideChildArchive () {
      this.dialogs.childArchive = null
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <MessageDialog
      v-if="dialogs.message"
      v-bind="dialogs.message"
      @close="cancelMessage"
    />
    <MessageMultipleDialog
      v-if="dialogs.messageMultiple"
      v-bind="dialogs.messageMultiple"
      @close="cancelMessage"
    />
    <ChildInfoDialog
      v-if="dialogs.childInfo"
      :child="dialogs.childInfo.child"
      @close="hideChildInfo"
      @startMessage="startChildMessage"
      @showNotes="showChildNotes"
      @moveToArchive="showChildArchive"
    />
    <ChildNotesDialog
      v-if="dialogs.childNotes"
      :child="dialogs.childNotes.child"
      @close="hideChildNotes"
    />
    <ChildArchiveDialog
      v-if="dialogs.childArchive"
      :child="dialogs.childArchive.child"
      @close="hideChildArchive"
    />
    <BaseSpinner v-if="isPending"/>
    <template v-else-if="groups && groups.length > 0">
      <div class="group-list landscape-only">
        <GroupCard
          v-for="group in groups"
          :group="group"
          :key="group.id"
          @startGroupMessage="startGroupMessage"
          @startTeacherMessage="startTeacherMessage"
          @showChildInfo="showChildInfo"
        />
      </div>
      <div class="alert-wrapper portrait-only">
        <BaseAlert color="warning">
          <div>Редактирование журнала недоступно</div>
          <div>в портретной ориентации.</div>
          <div>Пожалуйста, поверните устройство.</div>
        </BaseAlert>
      </div>
      <div class="controls">
        <template v-if="canConfirmMarks">
          <ConfirmMarksButton/>
          <div class="report-button-wrapper">
            <div class="report-button">
              <ReportButton :user="user"/>
            </div>
          </div>
        </template>
        <ReportButton v-else :user="user"/>
      </div>
    </template>
    <div v-else-if="groups" class="alert-wrapper">
      <BaseAlert color="warning">
        <div>У вас нет доступа ни к одной группе.</div>
        <div>Если вы уверены, что это ошибка, обратитесь к администратору.</div>
      </BaseAlert>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  position: relative;

  flex: 1;

  display: flex;
  flex-direction: column;
}

.group-list {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.controls {
  position: relative;

  margin-bottom: 2em;

  align-self: center;

  display: flex;
  align-items: center;
}

.report-button-wrapper {
  position: absolute;
  left: 100%;

  margin-left: 0.5em;

  display: flex;
}

.report-button {
  margin: 0 0.5em;
}

.alert-wrapper {
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (orientation: landscape) {
  .portrait-only {
    display: none;
  }
}

@media screen and (orientation: portrait) {
  .landscape-only {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .controls {
    flex-direction: column;
  }

  .report-button-wrapper {
    position: static;
    margin: 0;
  }
}
</style>
