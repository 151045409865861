<template>
  <router-view/>
</template>

<style>
#app {
  color: var(--dark-gray);

  font-family: 'Fira Sans', sans-serif;
  font-size: 12pt;
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  #app {
    background-image: url('static/images/background.png');
    background-size: cover;
    background-position: bottom;
    background-attachment: fixed;
  }
}

@media screen and (max-width: 600px) {
  #app {
    font-size: 8pt;
  }
}
</style>
