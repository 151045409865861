<script>
export default {
  name: 'BaseCloseButton',

  props: {
    left: { default: false },
    right: { default: true }
  }
}
</script>

<template>
  <div class="button-close" title="Закрыть" :class="{'left': left, 'right': right}">
    <FontAwesomeIcon icon="times" size="lg" fixed-width />
  </div>
</template>

<style scoped>
.button-close {
  color: var(--medium-gray);

  display: flex;
  justify-content: center;
  align-items: center;

  width: 2em;
  height: 2em;

  border-radius: 9999px;

  cursor: pointer;
}

.button-close.left {
  margin-left: -0.45em;
}

.button-close.right {
  margin-right: -0.45em;
}

.button-close:hover {
  color: white;
  background-color: var(--medium-gray);
}
</style>
