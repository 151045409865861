<template>
  <EmployeePage v-if="user.is_employee" :user="user"/>
  <ParentPage v-else-if="user.is_parent" :user="user"/>
  <KindergartenDirectorPage v-else-if="user.is_kindergarten_director" :user="user"/>
</template>

<script>
import ParentPage from '@/components/journal/parent/ParentPage.vue'
import EmployeePage from '@/components/journal/employee/EmployeePage.vue'
import KindergartenDirectorPage from '@/components/journal/kindergarten-director/KindergartenDirectorPage.vue'

export default {
  name: 'JournalPage',

  components: {
    ParentPage,
    EmployeePage,
    KindergartenDirectorPage
  },

  props: [
    'user'
  ]
}
</script>
