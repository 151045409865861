<template>
  <div class="calendar">
    <BaseMonthSelector
      v-model:date="monthStart"
      :showYearSelector="showYearSelector"
    >
      <div class="week-days">
        <div v-for="weekDay in weekDays" :key="weekDay" class="week-day">
          {{ weekDay }}
        </div>
      </div>
      <div class="days">
        <div
          v-for="day in days"
          :key="day.iso"
          :class="[
            'day', day.class,
            {
              'hoverable': isSelectable,
              'today': day.isToday,
              'not-current-month': !day.isCurrentMonth,
              'active': day.isActive,
            }
          ]"
          :title="day.tooltip"
          @click="isSelectable && day.isActive && $emit('select', day.date)"
        >
          {{ day.dayOfMonth }}
        </div>
      </div>
    </BaseMonthSelector>
  </div>
</template>

<script>
import { addDays, dateToISO } from '@/utils'

const weekDays = [
  'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'
]

export default {
  name: 'BaseCalendar',

  props: [
    'marks',
    'isSelectable',
    'showYearSelector',
    'activeStart',
    'activeEnd'
  ],

  data () {
    const now = new Date()

    return {
      monthStart: new Date(
        now.getFullYear(),
        now.getMonth(),
        1
      )
    }
  },

  computed: {
    weekDays () {
      return weekDays
    },

    firstWeekDay () {
      return this.monthStart.getDay() || 7
    },

    rangeFrom () {
      return addDays(this.monthStart, 1 - this.firstWeekDay)
    },

    rangeTo () {
      return addDays(this.rangeFrom, 6 * 7 - 1)
    },

    range () {
      const dates = []

      for (
        let date = this.rangeFrom;
        date <= this.rangeTo;
        date = addDays(date, 1)
      ) {
        dates.push(date)
      }

      return dates
    },

    days () {
      const now = new Date()

      const isToday = date => {
        return (
          date.getFullYear() === now.getFullYear() &&
          date.getMonth() === now.getMonth() &&
          date.getDate() === now.getDate()
        )
      }

      const isCurrentMonth = date => {
        return (
          date.getFullYear() === this.monthStart.getFullYear() &&
          date.getMonth() === this.monthStart.getMonth()
        )
      }

      const isActive = date => {
        if (this.activeStart && date < this.activeStart) return false
        if (this.activeEnd && date > this.activeEnd) return false
        return true
      }

      return this.range
        .map(date => {
          const iso = dateToISO(date)
          const mark = this.marks && this.marks[iso]

          return {
            date,
            iso,
            dayOfMonth: date.getDate(),
            isToday: isToday(date),
            isCurrentMonth: isCurrentMonth(date),
            isActive: isActive(date),
            class: mark && mark.class,
            tooltip: mark && mark.tooltip
          }
        })
    }
  },

  watch: {
    monthStart: {
      handler () {
        this.$emit('changeRange', {
          rangeFrom: dateToISO(this.rangeFrom),
          rangeTo: dateToISO(this.rangeTo)
        })
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.calendar {
  user-select: none;

  font-weight: 300;
}

.week-days {
  display: flex;
  justify-content: space-around;

  margin: 2em 0;

  font-size: 0.9em;
}

.days {
  display: grid;
  grid-template: repeat(6, 1fr) / repeat(7, 1fr);
  grid-row-gap: 0.2em;
  font-size: 1.5em;
  justify-items: center;
}

.day {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 2em;
  width: 2em;

  border-radius: 50%;

  cursor: default;
}

.not-current-month {
  color: lightgray;
}

.today {
  text-decoration: underline;
}

.selected {
  color: white;
  background-color: var(--green);
}

.selected {
  width: 100%;
  border-radius: 0;
}

.selected.start {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.selected.end {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.selected.start.end {
  width: 2em;
}

.transparent {
  opacity: 0.6;
}

.hoverable {
  cursor: not-allowed;
}

.hoverable.active {
  cursor: pointer;
}

.hoverable.active:hover {
  box-shadow: 0 0 5px var(--green);
}
</style>
