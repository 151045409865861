<template>
  <div v-on-clickaway="close" class="messages-wrapper">
    <MessagesButton
      @click="toggle"
      :isOpen="isOpen"
      title="Отправленные сообщения"
    >
      <font-awesome-icon icon="envelope" size="2x" />
    </MessagesButton>
    <keep-alive>
      <MessagesList
        v-if="isOpen"
        url="/outbox/"
        insertMessageEvent="sendMessage"
      />
    </keep-alive>
  </div>
</template>

<script>
import MessagesButton from '@/components/journal/messages/MessagesButton.vue'
import MessagesList from '@/components/journal/messages/MessagesList.vue'

import Toggleable from '@/mixins/Toggleable'

export default {
  name: 'MessagesOutbox',

  components: {
    MessagesButton,
    MessagesList
  },

  mixins: [Toggleable]
}
</script>

<style scoped>
.messages-wrapper {
  position: relative;
}

@media screen and (max-width: 768px) {
  .messages-wrapper {
    position: static;
  }
}
</style>
