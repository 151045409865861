<script>
export default {
  name: 'BaseBackButton',

  props: {
    left: { default: true },
    right: { default: false }
  }
}
</script>

<template>
  <div class="button-back" title="Назад" :class="{'left': left, 'right': right}">
    <FontAwesomeIcon icon="chevron-left" size="lg" fixed-width />
  </div>
</template>

<style scoped>
.button-back {
  color: var(--medium-gray);

  display: flex;
  justify-content: center;
  align-items: center;

  width: 2em;
  height: 2em;

  padding-right: 0.1em;

  border-radius: 9999px;

  cursor: pointer;
}

.button-back.left {
  margin-left: -0.45em;
}

.button-back.right {
  margin-right: -0.45em;
}

.button-back:hover {
  color: white;
  background-color: var(--medium-gray);
}
</style>
