<script>
import Toggleable from '@/mixins/Toggleable'

export default {
  name: 'RefundGuide',

  mixins: [Toggleable],

  inject: ['texts']
}
</script>

<template>
  <div class="wrapper">
    <a v-if="!isOpen" @click="toggle" class="toggle-link">
      <FontAwesomeIcon icon="info-circle" /> Условия возврата билетов
    </a>
    <template v-if="isOpen">
      <h3>Условия возврата билетов</h3>
      <p v-html="texts.ticket_refund_guide"></p>
    </template>
  </div>
</template>

<style scoped>
.wrapper {
  margin: 1em 0;
}

.toggle-link {
  cursor: pointer;
  border-bottom: 1px black dashed;
}
</style>
