<script>
import api from '@/api'
import { getErrors } from '@/errors'

export default {
  name: 'CorrectionRequestDialog',

  data () {
    return {
      isPending: false,
      isSubmitting: false,

      schema: null,
      errors: {},

      fields: [
        { name: 'description', value: '', type: 'textarea', rows: '20' }
      ]
    }
  },

  created () {
    this.fetchSchema()
  },

  methods: {
    fetchSchema () {
      this.isPending = true
      api.options('/correction-requests/')
        .then(response => {
          this.schema = response.data.actions.POST
        }, error => {
          this.$toast.error(getErrors(error).detail)
        })
        .then(() => {
          this.isPending = false
        })
    },

    submit () {
      if (this.isSubmitting || this.isPending) return

      this.isSubmitting = true
      api.post('/correction-requests/', this.values)
        .then(() => {
          this.close()
          this.$toast.success('Запрос отправлен')
          this.$emit('success')
        }, error => {
          this.errors = getErrors(error)
        })
        .then(() => {
          this.isSubmitting = false
        })
    },

    close () {
      this.$emit('close')
    }
  },

  computed: {
    values () {
      const values = {}
      this.fields.forEach(field => {
        values[field.name] = field.value
      })
      return values
    }
  }
}
</script>

<template>
  <div class="dialog-wrapper">
    <div class="dialog-overlay" @click="close"></div>
    <div class="dialog-box">
      <div class="dialog-header">
        <div class="dialog-header-text">
          Запрос на корректировку
        </div>
        <BaseCloseButton @click="close" />
      </div>
      <form @submit.prevent="submit">
        <div class="dialog-content">
          <BaseSpinner v-if="isSubmitting || isPending" size="50%" :withBackground="true"/>
          <template v-if="schema">
            <div v-for="field in fields" :key="field.name">
              <div class="register-form-group">
                <label
                  :for="field.name"
                  :class="['register-form-label', {required: schema[field.name].required}]"
                >
                  {{ schema[field.name].label }}
                </label>
                <div class="register-form-input-wrapper">
                  <textarea
                    v-if="field.type === 'textarea'"
                    v-model="field.value"
                    :id="field.name"
                    :required="schema[field.name].required"
                    :maxlength="schema[field.name].max_length"
                    :class="['form-input', 'register-form-input', {'has-errors': !!errors[field.name]}]"
                    :rows="field.rows || '4'"
                  />
                  <input
                    v-else
                    v-model="field.value"
                    :id="field.name"
                    :required="schema[field.name].required"
                    :maxlength="schema[field.name].max_length"
                    :class="['form-input', 'register-form-input', {'has-errors': !!errors[field.name]}]"
                  >
                </div>
              </div>
              <BaseFormError :errors="errors[field.name]" class="register-form-alert" />
            </div>
            <BaseFormError :errors="errors.non_field_errors" />
            <BaseFormError :errors="errors.detail" />
          </template>
        </div>
        <div class="dialog-buttons">
          <button type="submit" class="button no-margin" :disabled="isSubmitting || isPending">Отправить</button>
          <button type="button" class="button no-background no-margin" @click="close">Отмена</button>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>
.dialog-box {
  width: 100%;
}

.dialog-content {
  min-height: 100px;
}

.register-form-group {
  display: flex;
  align-items: center;
}

.register-form-label {
  width: 30%;
  padding-right: 1em;
  text-align: right;
  font-weight: 500;
}

.register-form-label.required::after {
  content: "*";
  color: var(--red);
}

.register-form-input-wrapper {
  flex: 1;
}

.register-form-input {
  color: var(--dark-text);
  width: 100%;
  padding: 0.8em 1em;
  text-align: left;
  font-weight: normal;
  border: 1px solid var(--medium-gray);
}

.register-form-alert {
  margin: 0 0 0.5em 30% !important;
}

.has-errors {
  border-style: dashed;
  border-color: var(--red-darker);
}

@media screen and (max-width: 768px) {
  .register-form-group {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 1em;
  }

  .register-form-label {
    width: 100%;
    text-align: left;
  }

  .register-form-alert {
    margin: 0 0 1em 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .dialog-box {
    width: 768px;
  }
}

</style>
