<script>
import InfiniteLoading from 'vue-infinite-loading'

import api from '@/api'
import { getErrors } from '@/errors'

export default {
  name: 'ChildNotesDialog',

  components: {
    InfiniteLoading
  },

  props: ['child'],

  data () {
    return {
      notes: []
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    fetchMore (state) {
      const limit = 10
      const offset = this.notes.length

      api.get('/child-notes/', {
        params: {
          child: this.child.id,
          limit,
          offset
        }
      })
        .then(response => {
          this.notes = this.notes.concat(response.data.results)

          if (response.data.results.length > 0) {
            state.loaded()
          }
          if (offset + response.data.results.length >= response.data.count) {
            state.complete()
          }
        }, error => {
          state.complete()
          this.$toast.error(getErrors(error).detail)
        })
    }
  }
}
</script>

<template>
  <div class="dialog-wrapper">
    <div class="dialog-overlay" @click="close"></div>
    <div class="dialog-box notes-dialog-box">
      <div class="dialog-header">
        <div class="dialog-header-text">
          {{ child.last_name }} {{ child.first_name }}
          <div class="dialog-header-text-subtitle">Комментарии</div>
        </div>
        <BaseCloseButton @click="close" />
      </div>
      <div class="dialog-content notes-dialog-content">
        <div v-for="note in notes" :key="note.id" class="note">
          <div class="note-text">{{ note.text }}</div>
          <div class="note-footer">
            <div class="note-signature">
              <div class="note-date">{{ $utils.formatDateTime(note.date_created) }}</div>
              <div class="note-name">{{ note.created_by }}</div>
            </div>
          </div>
        </div>
        <InfiniteLoading @infinite="fetchMore" spinner="spiral">
          <template #no-more>Больше нет комментариев.</template>
          <template #no-results>Нет комментариев.</template>
        </InfiniteLoading>
      </div>
    </div>
  </div>
</template>

<style scoped>
.notes-dialog-box {
  width: 40em;
  /*background-color: var(--light-gray);*/
}

.notes-dialog-content {
  max-height: 70vh;
  overflow: auto;
/*  margin: 0 0.75em 0.75em 0.75em;*/
/*  background-color: white;*/
/*  padding: 1.5em 0.75em;*/
/*  border-radius: 0.75em;*/
/*  height: 60vh;*/
}

.note {
  background: var(--light-gray);
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 1em;
}

.note-text {
  margin-bottom: 0.75em;
  white-space: pre-wrap;
}

.note-footer {
  display: flex;
  align-items: baseline;
}

.note-signature {
  font-size: 80%;
  display: flex;
  flex: 1;
  white-space: nowrap;
  width: 100%;
}

.note-date::after {
  content: "/";
  margin: 0 10px;
}

.note-name {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
