<script>
import api from '@/api'
import eventBus from '@/eventBus'
import { getErrors } from '@/errors'

export default {
  name: 'ChildArchiveDialog',

  props: ['child'],

  data () {
    return {
      isPending: false,
      isSubmitting: false,

      reasons: null,

      reasonSelectedID: null,
      reasonCustomText: ''
    }
  },

  computed: {
    isReasonCustom () {
      return this.reasonSelectedID === -1
    },

    reasonSelectedText () {
      if (!this.reasons || !this.reasonSelectedID) return null

      const reason = this.reasons.find(reason => reason.id === this.reasonSelectedID)
      return reason && reason.text
    },

    reasonText () {
      if (this.isReasonCustom) {
        return this.reasonCustomText
      }
      return this.reasonSelectedText
    }
  },

  created () {
    this.fetchReasonTemplates()
  },

  methods: {
    close () {
      this.$emit('close')
    },

    fetchReasonTemplates () {
      this.isPending = true

      api.get('/child-archive-reason-templates/')
        .then(response => {
          this.reasons = response.data
        }, error => {
          this.$toast.error(getErrors(error).detail)
        })
        .then(() => {
          this.isPending = false
        })
    },

    submit () {
      this.isSubmitting = true

      api.post(`/children/${this.child.id}/archive/`, {
        reason: this.reasonText
      })
        .then(response => {
          eventBus.emit('updateChild', this.child)
          this.$toast.success('Ребёнок перенесён в архив')
          this.close()
        }, error => {
          this.$toast.error(getErrors(error).detail)
        })
        .then(() => {
          this.isSubmitting = false
        })
    }
  }
}
</script>

<template>
  <div class="dialog-wrapper">
    <div class="dialog-overlay" @click="close"></div>
    <div class="dialog-box">
      <div class="dialog-header">
        <div class="dialog-header-text">Перенести в архив</div>
        <BaseCloseButton @click="close" />
      </div>
      <div class="dialog-content archive-dialog-content">
        <table class="child-info">
          <tbody>
            <tr>
              <td class="child-info-label">Имя</td>
              <td class="child-info-value">{{ child.last_name }} {{ child.first_name }}</td>
            </tr>
            <template v-if="child.kindergarten_group">
              <tr>
                <td class="child-info-label">Детский сад</td>
                <td class="child-info-value">{{ child.kindergarten_group.kindergarten.name }}</td>
              </tr>
              <tr>
                <td class="child-info-label">Группа</td>
                <td class="child-info-value">{{ child.kindergarten_group.name }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="child-info-label">Группа</td>
                <td class="child-info-value">{{ child.group.name }}</td>
              </tr>
            </template>
          </tbody>
        </table>
        <BaseSpinner v-if="isPending" size="50%" :withOverlay="false" />
        <fieldset v-else-if="reasons" class="fieldset">
          <legend class="fieldset-legend">Причина</legend>
          <div
            v-for="reason in reasons"
            :key="reason.id"
            class="form-radio"
          >
            <label>
              <input
                type="radio"
                :value="reason.id"
                v-model="reasonSelectedID"
              > {{ reason.text }}
            </label>
          </div>
          <div class="form-radio">
            <label>
              <input
                type="radio"
                :value="-1"
                v-model="reasonSelectedID"
              > Другое
            </label>
          </div>
          <textarea
            class="form-input text-area"
            v-if="isReasonCustom"
            v-model="reasonCustomText"
            v-focus
            :disabled="isSubmitting"
            spellcheck
          ></textarea>
        </fieldset>
      </div>
      <div class="dialog-buttons">
        <button
          class="button no-margin"
          :disabled="isSubmitting || !this.reasonText"
          @click="submit"
        >
          Отправить
        </button>
        <button type="button" class="button no-background no-margin" @click="close">Отмена</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.archive-dialog-content {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.child-info {
  width: 100%;
}

.child-info-label {
  font-weight: bold;
  text-align: right;
  vertical-align: top;
  padding-right: 1em;
  width: 40%;
}

.fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.fieldset-legend {
  font-weight: bold;
  margin-bottom: 1em;
}

.form-radio {
  display: block;
  margin-bottom: 0.5em;
}

.text-area {
  width: 100%;
  height: 10em;

  font-family: inherit;
  font-size: inherit;

  resize: none;

  color: var(--dark-text);
  padding: 0.8em 1em;
  text-align: left;
  font-weight: normal;
  border: 1px solid var(--medium-gray);
}
</style>
