<template>
  <div class="index-page" lang="ru">
    <div class="hero" :style="{'background-image': `url(${texts.header_image})`}">
      <div class="hero-content">
        <img class="hero-content-image" src="@/static/images/logo_white.svg">
        <div class="hero-content-text">{{ texts.header_image_text }}</div>
      </div>
      <button
        @click="scrollToRegisterSection"
        :class="[
          'button',
          'floating-button',
          'register-button',
          'shake',
          {'hidden': !isRegisterButtonVisible}
        ]"
        ref="registerButton"
      >
        Записаться на бесплатное занятие
      </button>
      <router-link
        to="/pay"
        :class="['button', 'floating-button', 'pay-button', {'hidden': isScrollingForward}]"
      >
        Пополнить баланс
      </router-link>
      <a
        v-if="texts.viber_bot_url"
        :href="texts.viber_bot_url"
        :class="['button', 'floating-button', 'viber-bot-button', {'hidden': isScrollingForward}]"
      >
        <FontAwesomeIcon :icon="['fab', 'viber']" />
        Viber
      </a>
    </div>
    <div class="main-content-wrapper">
      <div class="main-content">
        <div class="intro-section">
          <h1 class="section-header">{{ $utils.unbreak(texts.intro_header) }}</h1>
          <div class="intro-section-content">
            <div class="intro-quote quote-wrapper">
              <img src="@/static/images/quote.png" class="quote-image quote-image-first">
              <img src="@/static/images/quote.png" class="quote-image quote-image-second">
              <div class="quote-content">
                <div class="intro-quote-header">{{ texts.intro_quote_header }}</div>
                <div class="intro-quote-text">{{ $utils.hyphenate($utils.unbreak(texts.intro_quote_text)) }}</div>
                <div class="intro-quote-author">
                  <div class="intro-quote-author-name">{{ texts.intro_quote_author }}</div>
                  <div class="intro-quote-author-position">{{ texts.intro_quote_author_position }}</div>
                </div>
              </div>
            </div>
            <div class="intro-block-wrapper">
              <img class="intro-block-path" src="@/static/images/path.png">
              <div class="intro-block">
                <div class="intro-block-content">
                  <div
                    v-for="entry in texts.intro_entries"
                    :key="entry.id"
                    class="intro-block-entry"
                  >
                    <div class="intro-block-entry-header">
                      {{ $utils.unbreak(entry.header) }}
                    </div>
                    <div class="intro-block-entry-text">
                      {{ $utils.hyphenate($utils.unbreak(entry.text)) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="steps-section">
          <h1 class="section-header">{{ texts.steps_header }}</h1>
          <div class="steps-section-content">
            <div class="steps">
              <div class="step" v-for="(entry, i) in texts.steps_entries" :key="entry.id">
                <div class="step-planet">
                  <img v-if="planets[i]" :src="planets[i]">
                </div>
                <div class="step-number">
                  <img v-if="numbers[i]" :src="numbers[i]">
                </div>
                <div class="step-content">
                  <div class="step-header">{{ entry.header }}</div>
                  <div class="step-subheader">{{ $utils.unbreak(entry.subheader) }}</div>
                  <div class="step-text">{{ $utils.hyphenate($utils.unbreak(entry.text)) }}</div>
                </div>
              </div>
            </div>
            <div class="steps-section-sidebar">
              <div class="steps-quote quote-wrapper">
                <img src="@/static/images/quote.png" class="quote-image quote-image-first">
                <img src="@/static/images/quote.png" class="quote-image quote-image-second">
                <div class="steps-quote-content quote-content">
                  {{ $utils.hyphenate($utils.unbreak(texts.steps_quote_text)) }}
                </div>
              </div>
              <div class="steps-links">
                <a
                  v-if="texts.steps_curriculum"
                  :href="texts.steps_curriculum"
                  class="button steps-link"
                  target="_blank"
                >
                  <FontAwesomeIcon icon="file-pdf" fixed-width />
                  {{ texts.steps_curriculum_link_text }}
                </a>
                <a
                  v-if="texts.steps_curriculum2"
                  :href="texts.steps_curriculum2"
                  class="button steps-link"
                  target="_blank"
                >
                  <FontAwesomeIcon icon="file-pdf" fixed-width />
                  {{ texts.steps_curriculum2_link_text }}
                </a>
              </div>
              <div class="steps-stamp">
                <img src="@/static/images/stamp_approved.png" class="steps-stamp-image">
                <div class="steps-stamp-content">
                  <div class="steps-stamp-header">{{ texts.steps_stamp_header }}</div>
                  <div class="steps-stamp-text">{{ $utils.unbreak(texts.steps_stamp_text) }}</div>
                </div>
              </div>
              <div class="steps-planets">
                <img src="@/static/images/planets.png">
              </div>
            </div>
          </div>
        </div>
        <div class="diary-section">
          <div class="diary-block">
            <div class="diary-image-wrapper">
              <picture>
                <source srcset="@/static/images/diary.webp" type="image/webp">
                <source srcset="@/static/images/diary.png" type="image/png">
                <img src="@/static/images/diary.png" class="diary-image">
              </picture>
            </div>
            <div class="diary-content">
              <h1 class="diary-header">{{ texts.diary_header }}</h1>
              <div class="diary-text">{{ $utils.unbreak(texts.diary_text) }}</div>
              <div class="diary-list-header">{{ texts.diary_list_header }}</div>
              <ul class="diary-list">
                <li
                  v-for="entry in texts.diary_entries"
                  :key="entry.id"
                  class="diary-list-entry"
                >{{ $utils.unbreak(entry.text) }}</li>
              </ul>
              <div class="diary-present">
                <div class="diary-present-text">
                  {{ texts.diary_present_text }}
                </div>
                <img src="@/static/images/present.png" class="diary-present-image">
              </div>
            </div>
          </div>
        </div>
        <div class="register-section" ref="registerSection">
          <div class="register-block">
            <div class="register-header">{{ $utils.unbreak(texts.register_header) }}</div>
            <div class="register-subheader">{{ texts.register_subheader }}</div>
            <div class="register-text">{{ $utils.unbreak(texts.register_text) }}</div>
            <div class="register-strip-wrapper">
              <div class="strip strip-left register-strip">
                <div class="strip-header">{{ texts.phone_strip_text }}</div>
                <div class="strip-text">
                  {{ texts.phone_number }}
                  <a
                    v-if="texts.viber_bot_url"
                    :href="texts.viber_bot_url"
                    :class="['button', 'viber-bot-small-button']"
                  >
                    <FontAwesomeIcon :icon="['fab', 'viber']" />
                    Viber
                  </a>
                </div>
              </div>
              <div class="register-quote quote-wrapper">
                <img
                  src="@/static/images/quote.png"
                  class="quote-image quote-image-first register-quote-image"
                >
                <img
                  src="@/static/images/quote.png"
                  class="quote-image quote-image-second register-quote-image"
                >
                <div class="register-quote-content quote-content">
                  <div class="register-quote-header">
                    {{ $utils.unbreak(texts.register_quote_header) }}
                  </div>
                  <div class="register-quote-text">
                    {{ $utils.unbreak(texts.register_quote_text) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="register-stamp">
              <img src="@/static/images/stamp_diary.png">
              <div class="register-stamp-content">
                <div class="register-stamp-header">{{ texts.register_stamp_header }}</div>
                <div class="register-stamp-text">{{ $utils.unbreak(texts.register_stamp_text) }}</div>
              </div>
            </div>
            <div class="register-form-wrapper">
              <RegisterForm />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-copyright">&copy; {{ texts.copyright }}</div>
      <div class="footer-company-info"> {{ texts.company_info }}</div>
    </div>
  </div>
</template>

<script>
import RegisterForm from '@/components/landing/RegisterForm.vue'

import useScrollDirection from '@/composables/useScrollDirection'
import { isElementInViewport } from '@/utils'

export default {
  name: 'LandingPage',

  components: {
    RegisterForm
  },

  setup () {
    const isScrollingForward = useScrollDirection()

    return {
      isScrollingForward
    }
  },

  props: ['texts'],

  mounted () {
    window.addEventListener('scroll', this.onWindowScroll)
  },

  beforeUnmount () {
    window.removeEventListener('scroll', this.onWindowScroll)
  },

  data () {
    return {
      isRegisterButtonVisible: true,

      planets: [1, 2, 3].map(i => `${process.env.BASE_URL}images/planet${i}.png`),
      numbers: [1, 2, 3, 4].map(i => `${process.env.BASE_URL}images/number${i}.png`)
    }
  },

  methods: {
    onWindowScroll () {
      this.isRegisterButtonVisible = !isElementInViewport(this.$refs.registerSection)
    },

    scrollToRegisterSection () {
      this.$refs.registerSection.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }
}
</script>

<style scoped>
.index-page {
  color: var(--dark-text);
  background-color: white;
}

.hero {
  position: relative;

  height: 100vh;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
}

.hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-content-image {
  width: 35em;

  margin: 0.5em;

  filter: drop-shadow(0 0 0.5em rgba(0, 0, 0, 0.7));
}

.hero-content-text {
  color: white;

  font-size: 2.5em;

  text-shadow: 0 0 0.3em rgba(0, 0, 0, 0.8);
}

.floating-button {
  position: fixed;
  right: 1em;
  z-index: 2;
  font-size: 1.5em;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
}

.register-button {
  bottom: 2em;
}

.register-button.hidden {
  visibility: hidden;
  opacity: 0;
}

.pay-button {
  top: 2em;
}

.pay-button.hidden {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-5em);
}

.viber-bot-button {
  top: 5em;
  background-color: var(--viber);
}

.viber-bot-button.hidden {
  transform: translateY(-5em);
}

.hero-strip {
  position: absolute;

  bottom: 2em;
}

.strip {
  color: white;

  background-size: cover;
  background-repeat: no-repeat;

  padding: 0.5em 4em;

  font-weight: 500;
}

.strip-right {
  background-image: url('../../static/images/strip_red.svg');
  background-position: left;
  right: 0;
}

.strip-left {
  background-image: url('../../static/images/strip_green.svg');
  background-position: right;
  left: 0;
}

.strip-header {
  font-size: 1.7em;
}

.strip-text {
  font-size: 1.5em;
  height: 1.5em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.strip-text sup {
  font-size: 0.65em;
}

.viber-bot-small-button {
  background-color: #675da9;
  font-size: 0.6em;
  margin: 0 1em;
  min-width: 0;
}

.main-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-content {
  width: 1080px;
}

.section-header {
  font-size: 4em;

  margin-bottom: 1em;
}

.intro-section-content {
  display: flex;
}

.quote-wrapper {
  position: relative;
}

.quote-image {
  position: absolute;

  height: 5.7em;
}

.quote-image-first {
  left: 0;
  top: -2em;
}

.quote-image-second {
  right: 0;
  bottom: 0;

  transform: rotate(180deg);
}

.quote-content {
  position: relative;
}

.intro-quote {
  flex: 1;
}

.intro-quote-header {
  font-size: 2.8em;
  font-weight: 700;
}

.intro-quote-text {
  font-size: 1.2em;
  font-weight: 400;

  text-align: justify;

  margin: 1em 0;
}

.intro-quote-author-name {
  color: var(--green);
  font-weight: 700;
}

.intro-quote-author-position {
  font-style: italic;
}

.intro-block-wrapper {
  position: relative;

  width: 72%;
  margin-left: 2em;
  padding-top: 0.5em;
  padding-bottom: 0.2em;
}

.intro-block {
  padding: 0.5em;

  background-color: var(--medium-overlay);

  height: 100%;
}

.intro-block-path {
  position: absolute;
  z-index: 1;

  left: -2.5em;
  top: -4.5em;

  height: 7.2em;
}

.intro-block-content {
  display: flex;
  align-items: stretch;

  height: 100%;
}

.intro-block-entry {
  flex: 1;

  color: white;
  background-color: var(--green);

  padding: 2.5em 1.4em;
}

.intro-block-entry-header {
  font-size: 1.8em;
  font-weight: 700;

  height: 3em;
  width: 6.5em;
}

.intro-block-entry-text {
  font-weight: 500;
  line-height: 1.5;
}

.intro-block-entry:nth-child(2n) {
  background-color: var(--green-lighter);
}

.steps-section-content {
  display: flex;
}

.steps {
  flex: 1;
}

.step {
  display: flex;

  margin-bottom: 2em;
}

.step-planet {
  width: 15%;

  text-align: right;
}

.step-number {
  width: 22%;

  padding-top: 1em;

  text-align: center;
}

.step-content {
  flex: 1;
}

.step-header {
  color: var(--green);

  font-size: 2.5em;
  font-weight: 700;
}

.step:nth-child(4) .step-header {
  color: var(--red);
}

.step-subheader {
  font-size: 1.4em;
  font-weight: 500;

  margin: 0.4em 0;
}

.steps-section-sidebar {
  width: 25%;

  margin-left: 2em;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.steps-quote {
  font-size: 0.8em;
}

.steps-quote-content {
  font-size: 1.9em;
  font-weight: 700;

  padding: 1em 0.7em;

  transform: rotate(-1deg);
}

.steps-stamp {
  position: relative;

  width: 100%;

  margin-top: 4em;

  display: flex;
  justify-content: center;
  align-items: center;
}

.steps-stamp-image {
  width: 100%;
}

.steps-stamp-content {
  position: absolute;

  top: 40%;

  color: white;

  font-size: 0.8em;
  text-align: center;

  width: 60%;

  transform: rotate(4.5deg)
}

.steps-stamp-header {
  font-size: 1.5em;
  font-weight: 700;

  margin-bottom: 0.2em;
}

.steps-planets img {
  position: relative;
  right: -4em;
  width: 18em;
}

.steps-links {
  margin-top: 2em;
  margin-bottom: -1em;

  display: flex;
  flex-direction: column;

  gap: 1em;
}

.steps-link {
  margin: 0;
}

.diary-block {
  background-color: var(--green);

  width: 100%;
  height: 40em;

  padding: 2em;

  display: flex;
}

.diary-image-wrapper {
  position: relative;

  flex: 1;
}

.diary-image {
  position: absolute;

  right: 2em;

  height: 110%;
}

.diary-content {
  width: 45%;

  color: white;
}

.diary-header {
  font-size: 2.2em;
}

.diary-text {
  font-size: 1.3em;
  font-weight: 600;

  margin-bottom: 1.2em;
}

.diary-list-header {
  margin-bottom: 0.8em;

  font-weight: 500;
}

.diary-list {
  padding-left: 0;

  list-style: none;

  font-weight: 500;
}

.diary-list-entry {
  padding-left: 2em;
  margin-bottom: 0.8em;

  background-image: url('../../static/images/bullet.png');
  background-repeat: no-repeat;
  background-position-y: 0.1em;
  background-size: 1em;
}

.diary-present {
  position: relative;

  top: 4em;
}

.diary-present-text {
  position: relative;

  left: 1.7em;

  width: 13em;

  font-size: 1.5em;
  font-weight: 500;

  transform: rotate(2.5deg);
}

.diary-present-image {
  width: 14em;

  position: absolute;

  top: -80%;
  right: -5em;
}

.register-block {
  position: relative;

  color: white;
  background-color: var(--red);

  padding: 3em 4em 12em 4em;
  margin: 7em 0;
}

.register-header {
  font-size: 3.5em;
  font-weight: 700;

  letter-spacing: 0.02em;
  line-height: 1;

  width: 70%;

  margin-bottom: 0.5em;
}

.register-subheader {
  font-size: 1.5em;
  font-weight: 700;

  margin-bottom: 0.6em;
}

.register-text {
  width: 50%;
}

.register-strip-wrapper {
  position: absolute;
  left: 0;
  bottom: 4em;

  display: flex;
  align-items: center;

  margin-top: 2em;
  margin-right: 4em;
}

.register-quote {
  flex: 1;

  margin-left: 1.7em;
}

.register-quote-image {
  font-size: 0.4em;
}

.register-quote-content {
  padding: 1em;
}

.register-quote-header {
  font-size: 1.3em;
  font-weight: 500;

  margin-bottom: 0.2em;
}

.register-stamp {
  position: absolute;

  top: 3.5em;
  right: 5em;

  width: 14em;

  display: flex;
  justify-content: center;
  align-items: center;
}

.register-stamp-content {
  position: absolute;

  top: 40%;
  left: 2.2em;

  text-align: center;

  line-height: 0.9;

  width: 60%;

  transform: rotate(19deg);
}

.register-stamp-header {
  font-size: 2em;
  font-weight: 700;

  margin-bottom: 0.2em;
}

.register-form-wrapper {
  padding: 3em 0 1em 0;
}

.pre-footer {
  width: 100%;
}

.footer {
  position: relative;

  width: 100%;
  min-height: 7em;

  padding: 2em 0;

  color: #8c8c8c;
  background-color: #f2f2f2;

  font-size: 0.8em;
  font-weight: 400;

  text-align: center;
}

.footer-company-info {
  margin-top: 1em;
  white-space: pre-line;
}

@media screen and (max-width: 768px) {
  .hero {
    background-attachment: initial;
  }

  .hero-content {
    margin: 2em;
  }

  .hero-content-image {
    width: 100%;
  }

  .hero-content-text {
    font-size: 2em;

    white-space: nowrap;
  }

  .register-button {
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  .main-content {
    width: 100%;
    padding: 2em;

    overflow: hidden;
  }

  .intro-section-content {
    flex-direction: column;
  }

  .intro-block-wrapper {
    width: 100%;

    margin: 2em 0 0 0;
  }

  .intro-block-path {
    display: none;
  }

  .intro-block-content {
    flex-direction: column;
  }

  .intro-block-entry {
    padding: 2.5em 2em;
  }

  .intro-block-entry-header {
    width: 100%;
    height: auto;
    margin-bottom: 0.5em;
  }

  .steps-section-content {
    flex-direction: column;
  }

  .step-planet {
    display: none;
  }

  .step-number {
    width: 30%;
    padding: 1em;
  }

  .step-number img {
    width: auto;
    height: 7em;
  }

  .steps-section-sidebar {
    order: -1;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    margin: 0;
    padding: 1em 3em 3em 3em;
  }

  .steps-links {
    margin-bottom: 0;
  }

  .steps-stamp {
    display: none;
  }

  .steps-planets {
    display: none;
  }

  .diary-section {
    padding-top: 10em;
  }

  .diary-block {
    flex-direction: column;

    height: auto;
  }

  .diary-image-wrapper {
    flex: initial;

    height: 5.5em;
  }

  .diary-image {
    position: absolute;

    height: 18em;

    margin: auto;

    bottom: -1.5em;
    right: 0;
    left: 0;
  }

  .diary-content {
    width: 100%;
  }

  .diary-present {
    top: 0;

    margin: 4em 0;

    display: flex;
    justify-content: flex-end;
  }

  .diary-present-text {
    position: static;

    margin-right: 4em;
  }

  .register-block {
    position: static;

    padding: 2em;

    height: auto;
  }

  .register-header {
    font-size: 2em;
    width: 100%;
  }

  .register-subheader {
    width: 100%;
  }

  .register-text {
    width: 100%;
  }

  .register-stamp {
    display: none;
  }

  .register-strip-wrapper {
    position: static;

    flex-direction: column;
    align-items: flex-start;

    margin-right: 0;
  }

  .register-strip {
    position: absolute;
  }

  .register-quote {
    margin: 7em -1em 0 -1em;
  }
}
</style>
