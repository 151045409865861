<script>
export default {
  name: 'BaseFormError',

  props: ['errors'],

  computed: {
    isArray () {
      return Array.isArray(this.errors)
    }
  }
}
</script>

<template>
  <BaseAlert v-if="isArray" class="shake">
    <div v-for="(error, i) in errors" :key="i">
      {{ error }}
    </div>
  </BaseAlert>
  <BaseAlert v-else-if="errors" class="shake">{{ errors }}</BaseAlert>
</template>

<style scoped>

</style>
