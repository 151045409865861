import { onMounted, onBeforeUnmount, ref } from 'vue'

const useScrollDirection = (threshold = 10) => {
  let lastScrollY = 0

  const isForward = ref(false)

  const onWindowScroll = () => {
    const distance = Math.abs(window.scrollY - lastScrollY)

    if (distance >= threshold) {
      isForward.value = window.scrollY > lastScrollY
      lastScrollY = window.scrollY
    }
  }

  onMounted(() => {
    lastScrollY = window.scrollY
    window.addEventListener('scroll', onWindowScroll)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', onWindowScroll)
  })

  return isForward
}

export default useScrollDirection
