<script>
import { addDays, dateToISO } from '@/utils'

export default {
  name: 'BaseDateRangeSelector',

  props: [
    'range',
    'activeStart',
    'activeEnd'
  ],

  data () {
    return {
      dateStart: this.range && this.range.dateStart,
      dateEnd: this.range && this.range.dateEnd,
      isSelecting: false
    }
  },

  computed: {
    selectedDates () {
      if (!this.dateStart) {
        return []
      }

      if (!this.dateEnd) {
        return [this.dateStart]
      }

      const dates = []

      for (
        let date = this.dateStart;
        date <= this.dateEnd;
        date = addDays(date, 1)
      ) {
        dates.push(date)
      }

      return dates
    },

    marks () {
      const marks = {}

      for (const [index, date] of this.selectedDates.entries()) {
        marks[dateToISO(date)] = {
          class: [
            'selected',
            {
              start: index === 0,
              end: index === this.selectedDates.length - 1,
              transparent: this.isSelecting
            }
          ]
        }
      }

      return marks
    }
  },

  methods: {
    onDateSelect (date) {
      if (!this.isSelecting) {
        this.dateStart = date
        this.dateEnd = null
        this.isSelecting = true
      } else {
        this.dateEnd = date
        this.isSelecting = false

        if (this.dateEnd < this.dateStart) {
          [this.dateStart, this.dateEnd] = [this.dateEnd, this.dateStart]
        }

        this.$emit('update:range', {
          dateStart: this.dateStart,
          dateEnd: this.dateEnd
        })
      }
    }
  }
}
</script>

<template>
  <BaseCalendar
    :isSelectable="true"
    :marks="marks"
    @select="onDateSelect"
    :activeStart="activeStart"
    :activeEnd="activeEnd"
  />
</template>
