<script setup>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import api from '@/api'
import { getErrors } from '@/errors'
import { getToken } from '@/authToken'

const route = useRoute()
const router = useRouter()

const step = ref(1)

const isSubmitting = ref(false)
const errors = ref({})

const childID = ref(route.query.child || '')
const childName = ref()
const amount = ref()
const paymentMethod = ref('SBP')

onMounted(() => {
  // TODO: just draw a spinner or something

  if (childID.value) {
    submitStep1()
  }
})

const submitStep1 = () => {
  isSubmitting.value = true
  errors.value = {}

  api.get('/payment-info/', {
    params: { child: childID.value }
  })
    .then(response => {
      childName.value = response.data.child_name
      amount.value = response.data.amount
      step.value++
    }, error => {
      errors.value = getErrors(error)
    })
    .then(() => {
      isSubmitting.value = false
    })
}

const submitStep2 = () => {
  isSubmitting.value = true
  errors.value = {}

  api.post('/initialize-payment/', {
    child: childID.value,
    amount: amount.value,
    payment_method: paymentMethod.value
  })
    .then(response => {
      window.location = response.data.payment_url
    }, error => {
      errors.value = getErrors(error)
      isSubmitting.value = false
    })
}

const goBack = () => {
  step.value--
  errors.value = {}
}

const close = () => {
  if (getToken()) {
    router.push('/journal')
  } else {
    router.push('/')
  }
}
</script>

<template>
  <div class="content">
    <div class="dialog-wrapper">
      <div class="dialog-overlay medium" @click="close"></div>
      <div class="dialog-box">
        <div class="dialog-header">
          <BaseBackButton v-if="step > 1" @click="goBack" />
          <div class="dialog-header-text">Пополнение баланса</div>
          <BaseCloseButton @click="close" />
        </div>
        <form v-if="step === 1" @submit.prevent="submitStep1">
          <div class="dialog-content">
            <div class="form-group">
              <label class="form-label" for="child">
                ID ребёнка
              </label>
              <div class="form-input-wrapper">
                <input
                  id="child"
                  v-model="childID"
                  :class="['form-input', {'has-errors': !!errors.child}]"
                  :disabled="isSubmitting"
                  required
                  v-focus
                >
              </div>
            </div>
            <BaseFormError :errors="errors.child" class="form-alert" />
            <BaseFormError :errors="errors.non_field_errors" />
            <BaseFormError :errors="errors.detail" />
          </div>
          <div class="dialog-buttons">
            <button
              class="button green no-margin"
              :disabled="isSubmitting || !childID"
            >
              Продолжить
            </button>
          </div>
        </form>
        <form v-if="step === 2" @submit.prevent="submitStep2">
          <div class="dialog-content">
            <div class="form-group">
              <label class="form-label" for="child-name">
                Ребёнок
              </label>
              <div class="form-input-wrapper">
                <input
                  id="child-name"
                  :value="childName"
                  class="form-input"
                  readonly
                >
              </div>
            </div>
            <div class="form-group">
              <label class="form-label" for="amount">
                Сумма
              </label>
              <div class="form-input-wrapper">
                <input
                  id="amount"
                  v-model="amount"
                  :class="['form-input', {'has-errors': !!errors.amount}]"
                  required
                  v-focus
                >
              </div>
            </div>
            <BaseFormError :errors="errors.amount" class="form-alert" />
            <fieldset class="fieldset">
              <legend class="fieldset-legend">Способ оплаты</legend>
              <div class="form-radio">
                <label>
                  <input
                    type="radio"
                    value="SBP"
                    v-model="paymentMethod"
                  > СБП (приложение или QR-код)
                </label>
              </div>
              <div class="form-radio">
                <label>
                  <input
                    type="radio"
                    value="CARD"
                    v-model="paymentMethod"
                  > Банковская карта (комиссия 3%)
                </label>
              </div>
            </fieldset>
            <BaseFormError :errors="errors.payment_method" class="form-alert" />
            <BaseFormError :errors="errors.non_field_errors" />
            <BaseFormError :errors="errors.detail" />
          </div>
          <div class="dialog-buttons">
            <button
              class="button no-margin"
              :disabled="isSubmitting || !amount"
            >
              Перейти к оплате
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.dialog-box {
  width: 32em;
}

.content {
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-content {
  display: flex;
  flex-direction: column;
}

.fieldset {
  border: none;
  padding: 0;
  margin: 1em 0 0 0;

  width: 70%;
  align-self: flex-end;
}

.fieldset-legend {
  font-weight: bold;
  margin-bottom: 1em;
  padding: 0;
}

.form-radio {
  display: block;
  margin-bottom: 0.5em;
}

.form-group {
  display: flex;
  align-items: center;
}

.form-label {
  flex: 1;
  padding-right: 1em;
  text-align: right;
  font-weight: bold;
}

.form-input-wrapper {
  width: 70%;
}

.form-input {
  color: var(--dark-text);
  width: 100%;
  padding: 0.8em 1em;
  text-align: left;
  font-weight: normal;
  border: 1px solid var(--medium-gray);
}

.form-input[readonly] {
  background: var(--light-gray);
  border-color: transparent;
  outline: none !important;
}

.form-input.has-errors {
  border-style: dashed;
  border-color: var(--red-darker);
}

.form-alert {
  margin: 0 0 0.5em 30% !important;
}

@media screen and (max-width: 768px) {
  .dialog-box {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: auto;
    height: auto;

    margin: 0;
    border-radius: 0;
  }

  .dialog-header {
    border-radius: 0;
  }

  .form-group {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 1em;
  }

  .form-label {
    text-align: left;
    margin-bottom: 0.5em;
  }

  .form-input-wrapper {
    width: 100%;
  }

  .form-alert {
    margin: 0 0 1em 0 !important;
  }

  .fieldset {
    margin: 0;
    width: 100%;
  }
}
</style>
