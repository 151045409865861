export function addDays (date, days) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + days
  )
}

export function addMonths (date, months) {
  return new Date(
    date.getFullYear(),
    date.getMonth() + months,
    date.getDate()
  )
}

export function addYears (date, years) {
  return new Date(
    date.getFullYear() + years,
    date.getMonth(),
    date.getDate()
  )
}

export function dateToISO (date) {
  const year = date.getFullYear()
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  return `${year}-${month}-${day}`
}

export function pluralize (number, one, few, many) {
  const last = number % 10
  const lastTwo = number % 100

  if (lastTwo >= 11 && lastTwo <= 14) {
    return many
  }
  if (last === 1) {
    return one
  }
  if (last >= 2 && last <= 4) {
    return few
  }
  return many
}

export function isElementInViewport (el) {
  const rect = el.getBoundingClientRect()

  return (
    rect.top <= window.innerHeight &&
    rect.bottom >= 0 &&
    rect.left <= window.innerWidth &&
    rect.right >= 0
  )
}

export function keyToCmp (key, reverse = false, nullsLast = false) {
  return (obj1, obj2) => {
    let value1 = key(obj1)
    let value2 = key(obj2)

    if (value1 === value2) return 0

    if (reverse) {
      [value1, value2] = [value2, value1]
    }

    if (value1 === null || value1 === undefined) {
      return nullsLast ? 1 : -1
    }

    if (value2 === null || value2 === undefined) {
      return nullsLast ? -1 : 1
    }

    return value1 > value2 ? 1 : -1
  }
}
