<script>
export default {
  name: 'PayRoot'
}
</script>

<template>
  <div class="pay-page">
    <BaseNavigation></BaseNavigation>
    <router-view/>
  </div>
</template>

<style scoped>
.pay-page {
  display: flex;
  flex-direction: column;

  height: 100vh;
}

@media screen and (max-width: 600px) {
  .pay-page {
    font-size: 12pt;
  }
}
</style>
