import api from '@/api'

function urlB64ToUint8Array (base64String) {
  /*
  *  Push Notifications codelab
  *  Copyright 2015 Google Inc. All rights reserved.
  *
  *  Licensed under the Apache License, Version 2.0
  */

  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export function hasSupport () {
  return ('serviceWorker' in navigator) && ('PushManager' in window) && ('Notification' in window)
}

export function isDenied () {
  return Notification.permission === 'denied'
}

export async function getSubscription () {
  const registration = await navigator.serviceWorker.ready
  return registration.pushManager.getSubscription()
}

export async function subscribe () {
  const registration = await navigator.serviceWorker.ready
  return registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlB64ToUint8Array(process.env.VUE_APP_VAPID_PUBLIC_KEY)
  })
}

export async function registerSubscription (subscription) {
  return api.post('/push-subscriptions/', subscription)
}
