<script>
import PriceListDialog from '@/components/journal/parent/child/PriceListDialog.vue'

import api from '@/api'
import { getErrors } from '@/errors'

export default {
  name: 'ChildInfo',

  components: {
    PriceListDialog
  },

  props: ['child'],

  data () {
    return {
      subscriptions: null,
      subscriptionsPending: false,
      isPriceListDialogVisible: false
    }
  },

  created () {
    this.fetchSubscriptions()
  },

  computed: {
    payPageURL () {
      return `/pay/?child=${this.child.id}`
    }
  },

  methods: {
    fetchSubscriptions () {
      this.subscriptionsPending = true

      api.get('/subscriptions/', {
        params: { child: this.child.id }
      })
        .then(response => {
          this.subscriptions = response.data
        }, error => {
          this.$toast.error(getErrors(error).detail)
        })
        .then(() => {
          this.subscriptionsPending = false
        })
    },

    openViberBot () {
      api.get('/bots/viber/url/')
        .then(response => {
          window.location = response.data.url
        })
    }
  }
}
</script>

<template>
  <PriceListDialog
    v-if="isPriceListDialogVisible"
    @close="isPriceListDialogVisible = false"
  />
  <div class="info">
    <dl class="info-items">
      <div class="info-item">
        <dd class="money">{{ $utils.formatDecimal(child.balance) }}</dd>
        <dt>Баланс</dt>
      </div>
      <div class="info-item" v-if="child.free_attendances > 0">
        <dd>{{ child.free_attendances }}</dd>
        <dt class="desktop-only">
          {{ $utils.pluralize(child.free_attendances, 'Бесплатное занятие', 'Бесплатных занятия', 'Бесплатных занятий') }}
        </dt>
        <dt class="mobile-only">Бесплатно</dt>
      </div>
      <div class="info-item" v-if="subscriptions && subscriptions.length === 0">
        <dd class="money">{{ $utils.formatDecimal(child.retail_price) }}</dd>
        <dt class="desktop-only">Цена за занятие</dt>
        <dt class="mobile-only">Цена</dt>
      </div>
    </dl>
    <div class="subscription-list" v-if="subscriptions">
      <div
        v-for="subscription in subscriptions"
        :key="subscription.id"
        class="subscription-card"
      >
        <div class="subscription-body">
          <div class="subscription-name">{{ subscription.name }}</div>
          <div class="subscription-valid-until" v-if="subscription.date_end">
            по {{ $utils.formatDate(subscription.date_end) }}
          </div>
          <div class="subscription-attendances">
            {{ subscription.attendances_left < 0 ? '∞' : subscription.attendances_left }}
          </div>
          <div class="subscription-attendances-label">
            {{ $utils.pluralize(subscription.attendances_left, 'занятие', 'занятия', 'занятий') }}
            осталось
          </div>
        </div>
        <div class="subscription-footer">Абонемент</div>
      </div>
    </div>
    <div class="buttons">
      <router-link class="button green pay-button" :to="payPageURL">
        Пополнить баланс
      </router-link>
      <button class="button green pay-button" @click="isPriceListDialogVisible = true">
        Прейскурант
      </button>
      <button class="button pay-button viber-button" @click="openViberBot">
        <FontAwesomeIcon :icon="['fab', 'viber']" />
        Viber-робот
      </button>
      <a
        v-if="child.kindergarten_group && child.kindergarten_group.kindergarten.viber_link"
        :href="child.kindergarten_group.kindergarten.viber_link"
        target="_blank"
        rel="noopener noreferrer"
        class="button pay-button viber-button"
      >
        <FontAwesomeIcon :icon="['fab', 'viber']" />
        Сообщество в Viber
      </a>
    </div>
  </div>
</template>

<style scoped>
.info {
  flex: 2;

  padding: 1.5em 1em 2.3em 1em;

  background-image: url('../../../../static/images/info_background.svg');
  background-position: 30%;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.info-items {
  margin: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info-item {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0.5em 0;

  font-weight: bold;
  text-align: center;

  text-shadow: 0px 1px 1px var(--shadow);
}

.info-item dt {
  flex: 1;

  color: white;

  white-space: nowrap;
}

.info-item dd {
  margin: 0;

  color: white;

  font-size: 4.5em;
}
.subscription-list {
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  margin: 0 1em;
}

.subscription-card {
  background-color: white;

  margin: 1em 0;

  border-radius: 1em;
  box-shadow: 0 1px 5px var(--shadow);

  text-align: center;

  overflow: hidden;
}

.subscription-body {
  padding: 1em;
}

.subscription-name {
  font-weight: bold;

  margin-bottom: 0.5em;
}

.subscription-attendances {
  font-size: 2em;

  font-weight: bold;
}

.subscription-valid-until {
  font-size: 0.8em;
  font-weight: 300;
}

.subscription-footer {
  background-color: var(--light-gray);

  font-weight: bold;

  padding: 0.5em;

  text-shadow: 0 1px 0 white;
}

.highlight {
  color: var(--green);
}

.small {
  font-size: 0.7em;
}

.money {
  position: relative;
}

.money::after {
  content: "₽";
  font-size: 0.3em;
  position: absolute;
  bottom: 0.45em;
}

.buttons {
  display: flex;
  flex-direction: column;
}

.pay-button {
  margin: 1em 1em 0 1em !important;
  align-self: stretch;
}

.viber-button {
  background-color: var(--viber);
}

.mobile-only {
  display: none;
}

@media screen and (max-width: 768px) {
  .info {
    background-position: 0% 90%;

    padding: 1.5em 0;

    align-items: stretch;
  }

  .info-items {
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
  }

  .info-item {
    margin: 0;
    flex: 1;
  }

  .info-item dd {
    font-size: 3em;
  }

  .subscription-card {
    min-width: 50%;
    align-self: center;
  }

  .pay-button {
    align-self: center;
  }

  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: unset;
  }
}
</style>
