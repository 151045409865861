import { createRouter, createWebHistory } from 'vue-router'

import IndexRoot from '@/components/IndexRoot.vue'
import LoginPage from '@/components/LoginPage'
import JournalRoot from '@/components/journal/JournalRoot.vue'
import JournalPage from '@/components/journal/JournalPage.vue'
import EmployeeJournal from '@/components/journal/employee/EmployeeJournal.vue'
import RegistrationRequestsPage from '@/components/journal/employee/requests/RegistrationRequestsPage.vue'
import CorrectionRequestsPage from '@/components/journal/employee/requests/CorrectionRequestsPage.vue'
import ChangePasswordPage from '@/components/journal/ChangePasswordPage.vue'
import PayRoot from '@/components/pay/PayRoot.vue'
import PayPage from '@/components/pay/PayPage.vue'
import PayResult from '@/components/pay/PayResult.vue'
import NotFoundPage from '@/components/NotFoundPage.vue'

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: IndexRoot
    },
    {
      path: '/login',
      component: LoginPage
    },
    {
      path: '/journal',
      component: JournalRoot,
      children: [
        {
          path: '',
          component: JournalPage,
          children: [
            {
              path: '',
              component: EmployeeJournal
            },
            {
              path: 'requests',
              component: RegistrationRequestsPage
            },
            {
              path: 'corrections',
              component: CorrectionRequestsPage
            }
          ]
        },
        {
          path: 'change-password',
          component: ChangePasswordPage
        }
      ]
    },
    {
      path: '/pay',
      component: PayRoot,
      children: [
        {
          path: '',
          component: PayPage
        },
        {
          path: 'result',
          component: PayResult
        }
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      component: NotFoundPage
    }
  ]
})
