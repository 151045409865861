<template>
  <div class="card">
    <div class="card-content">
      <div class="header">
        <div>
          <h2 class="header-text">
            {{ child.last_name }} {{ child.first_name }}
          </h2>
          <div class="header-subtext">
            {{ child.group.name }}
            <small v-if="child.kindergarten_group">
              / {{ child.kindergarten_group.name }}
            </small>
          </div>
        </div>
        <div class="header-id">
          ID: {{ child.id }}
        </div>
      </div>
      <div class="detail">
        <ChildInfo :child="child" />
        <div class="attendances">
          <div
            v-if="child.unpaid_attendance_count > 0"
            class="unpaid-warning"
          >
            <FontAwesomeIcon icon="exclamation-triangle" fixed-width />
            У вас {{ child.unpaid_attendance_count }}
            {{ $utils.pluralize(child.unpaid_attendance_count, 'неоплаченное посещение', 'неоплаченных посещения', 'неоплаченных посещений') }}
          </div>
          <BaseCalendar
            :marks="attendancesByDate"
            @changeRange="fetchAttendances"
          />
          <ul class="attendances-legend">
            <li><i class="attendance marked confirmed"></i> посещение оплачено</li>
            <li><i class="attendance marked"></i> списание средств в процессе</li>
            <li><i class="attendance marked unpaid"></i> не хватает средств для оплаты</li>
          </ul>
          <BaseSpinner v-if="isPending" :withBackground="true"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChildInfo from '@/components/journal/parent/child/ChildInfo.vue'

import api from '@/api'
import { getErrors } from '@/errors'

export default {
  name: 'ChildCard',

  components: {
    ChildInfo
  },

  props: [
    'child'
  ],

  data () {
    return {
      attendances: [],
      isPending: false
    }
  },

  computed: {
    attendancesByDate () {
      const result = {}

      for (const attendance of this.attendances) {
        result[attendance.date] = {
          tooltip: attendance.receipt && (
            attendance.receipt.subscription
              ? `По абонементу "${attendance.receipt.subscription.name}"`
              : `Стоимость занятия: ${attendance.receipt.amount}`
          ),
          class: [
            'marked',
            attendance.receipt && 'confirmed',
            attendance.is_unpaid && 'unpaid'
          ]
        }
      }

      return result
    }
  },

  methods: {
    fetchAttendances ({ rangeFrom, rangeTo }) {
      this.attendances = []
      this.isPending = true

      api.get('/journal/attendances/', {
        params: {
          child: this.child.id,
          date__gte: rangeFrom,
          date__lte: rangeTo
        }
      })
        .then(response => {
          this.attendances = response.data
        }, error => {
          this.$toast.error(getErrors(error).detail)
        })
        .then(() => {
          this.isPending = false
        })
    }
  }
}
</script>

<style scoped>
.card {
  width: 768px;

  margin: 1em 0;
  padding: 1em;

  border-radius: 1em;

  background-color: var(--medium-overlay);
}

.card-content {
  border-radius: 0.5em;
  overflow: hidden;
}

.header {
  padding: 2em;

  background-image: url('../../../../static/images/cloud.svg');
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: var(--green);

  color: white;

  text-shadow: 0px 1px 1px var(--shadow);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-text {
  margin: 0;

  font-size: 2em;
}

.header-subtext {
  margin-top: 0.3em;
}

.header-id {
  font-size: 1.5em;
  font-weight: 700;

  opacity: 0.9;
}

.detail {
  display: flex;
}

.attendances {
  flex: 3;

  position: relative;
  padding: 2em;

  background-color: white;
}

.attendances :deep(.marked) {
  border: 0.2em solid var(--green);
  background-color: var(--green-lightest);
}

.attendances :deep(.marked).unpaid {
  border: 0.2em solid var(--red);
  color: var(--red-darker);
  background-color: var(--red-lightest);
}

.attendances :deep(.marked).confirmed {
  color: white;
  background-color: var(--green);
}

.attendances :deep(.marked).unpaid.confirmed {
  background-color: var(--red);
}

.unpaid-warning {
  color: var(--red-darker);
  margin-bottom: 2em;
  text-align: center;
}

.attendances-legend {
  list-style: none;
  padding: 0;
  margin: 2em 0 0 0;
}

.attendances-legend li {
  line-height: 1.7em;
}

.attendances-legend .attendance {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 9999px;
  margin-bottom: -0.2em;
  margin-right: 0.3em;
}

@media screen and (max-width: 768px) {
  .card {
    width: 100%;

    padding: 0;
    margin: 0;

    border-radius: 0;
  }

  .card-content {
    border-radius: 0;
  }

  .detail {
    flex-direction: column;
  }
}
</style>
