<template>
  <div class="change-password-page">
    <div class="dialog-overlay medium" @click="close"></div>
    <div class="form-box">
      <BaseSpinner v-if="isPending"/>
      <BaseCloseButton @click="close" class="button-close-floating" />
      <div class="logo">
        <img src="../../static/images/logo.png"/>
      </div>
      <BaseAlert v-if="errors.non_field_errors">
        <div v-for="(error, i) in errors.non_field_errors" :key="i">
          {{ error }}
        </div>
      </BaseAlert>
      <BaseAlert v-if="errors.detail">{{ errors.detail }}</BaseAlert>
      <form class="form" @submit.prevent="changePassword">
        <input
          name="current_password"
          type="password"
          placeholder="Текущий пароль"
          :class="['form-input', {'has-errors': !!errors.current_password}]"
          v-model="currentPassword"
          v-focus
          :disabled="isPending"
          required
        >
        <BaseAlert v-if="errors.current_password">
          <div v-for="(error, i) in errors.current_password" :key="i">
            {{ error }}
          </div>
        </BaseAlert>
        <input
          name="new_password"
          type="password"
          placeholder="Новый пароль"
          :class="['form-input', {'has-errors': !!errors.new_password}]"
          v-model="newPassword"
          :disabled="isPending"
          required
        >
        <BaseAlert v-if="errors.new_password">
          <div v-for="(error, i) in errors.new_password" :key="i">
            {{ error }}
          </div>
        </BaseAlert>
        <input
          name="new_password_repeat"
          type="password"
          placeholder="Повторите новый пароль"
          :class="['form-input', {'has-errors': newPasswordRepeat && !passwordsMatch}]"
          v-model="newPasswordRepeat"
          :disabled="isPending"
          required
        >
        <BaseAlert v-if="newPasswordRepeat && !passwordsMatch">
          Пароли не совпадают.
        </BaseAlert>
        <button
          :disabled="!submitEnabled"
          class="button"
        >
          Подтвердить
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { getErrors } from '@/errors'

export default {
  name: 'ChangePasswordPage',

  props: [
    'user'
  ],

  data () {
    return {
      currentPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
      isPending: false,
      errors: {}
    }
  },

  computed: {
    passwordsMatch () {
      return this.newPassword === this.newPasswordRepeat
    },

    filled () {
      return this.currentPassword && this.newPassword && this.newPasswordRepeat
    },

    submitEnabled () {
      return !this.isPending && this.filled && this.passwordsMatch
    }
  },

  methods: {
    changePassword () {
      this.isPending = true
      this.errors = {}

      api.post('/change-password/', {
        current_password: this.currentPassword,
        new_password: this.newPassword
      })
        .then(response => {
          this.$emit('changePassword')
          this.$toast.success('Пароль успешно изменён')
          this.close()
        }, error => {
          this.errors = getErrors(error)
        })
        .then(() => {
          this.isPending = false
        })
    },

    close () {
      this.$router.push('/journal')
    }
  }
}
</script>

<style scoped>
.change-password-page {
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-close-floating {
  position: absolute;

  top: 1.5em;
  right: 1.5em;
}
</style>
