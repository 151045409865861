<template>
  <div class="not-found-page">
    <BaseNavigation></BaseNavigation>
    <div class="content">
      <BaseAlert>
        <div>Страница не найдена</div>
        <router-link to="/" class="main-page-link">
          Перейти на главную
        </router-link>
      </BaseAlert>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage'
}
</script>

<style scoped>
.not-found-page {
  display: flex;
  flex-direction: column;

  height: 100vh;
}

.content {
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}

.main-page-link {
  padding: 0.3em 2em;
  margin: 0.8em 0 0.2em;

  border-radius: 9999px;

  color: var(--red);
  background-color: white;

  text-decoration: none;
}
</style>
