<script>
import RegistrationRequestDialog from '@/components/journal/employee/requests/RegistrationRequestDialog.vue'

import api from '@/api'
import { getErrors } from '@/errors'

export default {
  name: 'RegistrationRequestsPage',

  components: {
    RegistrationRequestDialog
  },

  props: [
    'user'
  ],

  data () {
    return {
      requests: null,
      isPending: false,

      isDialogVisible: false
    }
  },

  created () {
    if (!this.user.is_teacher) {
      this.$router.push('/journal')
    }

    this.fetchRequests()
  },

  methods: {
    fetchRequests () {
      this.isPending = true
      api.get('/teachers-registration-requests/')
        .then(response => {
          this.requests = response.data
        }, error => {
          this.$toast.error(getErrors(error).detail)
        })
        .then(() => {
          this.isPending = false
        })
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <RegistrationRequestDialog
      v-if="isDialogVisible"
      @close="isDialogVisible = false"
      @success="fetchRequests"
    />
    <div class="column">
      <div class="buttons">
        <button
          @click="isDialogVisible = true"
          class="button green no-margin"
        >
          <FontAwesomeIcon icon="plus" fixed-width />
          Создать запрос
        </button>
      </div>
      <BaseSpinner v-if="isPending" />
      <template v-else-if="requests && requests.length > 0">
        <div class="table-wrapper">
          <table class="table">
            <thead>
              <tr>
                <th>Фамилия, имя</th>
                <th>Сад</th>
                <th>Группа</th>
                <th>Дата создания</th>
                <th>Обработан</th>
                <th>Статус</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="request in requests" :key="request.id">
                <td>{{ request.child_last_name }} {{ request.child_first_name }}</td>
                <td>{{ request.child_kindergarten }}</td>
                <td>{{ request.child_kindergarten_group }}</td>
                <td>{{ $utils.formatDateTime(request.date_created) }}</td>
                <td>
                  <span v-if="request.processed_by">
                    {{ $utils.formatDateTime(request.date_processed) }} ({{ request.processed_by }})
                  </span>
                </td>
                <td :class="['request-status', request.status.toLowerCase()]">
                  <FontAwesomeIcon v-if="request.status === 'PENDING'" icon="clock" fixed-width />
                  <FontAwesomeIcon v-else-if="request.status === 'ACCEPTED'" icon="check" fixed-width />
                  <FontAwesomeIcon v-else-if="request.status === 'REJECTED'" icon="ban" fixed-width />
                  {{ request.status_display }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  position: relative;

  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.column {
  width: 1200px;

  flex: 1;

  display: flex;
  flex-direction: column;

  padding: 0 1em;
}

.table-wrapper {
  overflow: auto;
  min-height: 6em;
  margin: 1em 0;
}

.request-status.pending {
  color: var(--teal);
}

.request-status.accepted {
  color: var(--green-darker);
}

.request-status.rejected {
  color: var(--red-darker);
}

@media screen and (max-width: 1200px) {
  .column {
    width: 100%;
  }
}
</style>
